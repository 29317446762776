@mixin transition-opt(
 	/* to change defaults go to _defaults.scss */
	$hover-color: $default-hover-color,
	$transition-color: $default-hover-transition-color,
	$transition-speed: $default-hover-transition-speed,
	$padding: $default-hover-padding,
	$display: $default-hover-display,
	$transition-easing: $easing,
	$delay-after: $without-delay,
	$delay-before: $without-delay) {

	display: $display;
	position: relative;
	z-index: 2;
	text-decoration: none;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-webkit-user-select: none; 
	-moz-user-select: none; 
	-ms-user-select: none; 
	user-select: none; 
	-webkit-touch-callout: none; 
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0); 
	padding: $padding;
	white-space: nowrap;
	overflow: hidden;
	-webkit-transition: color $transition-speed $transition-easing; 
	-moz-transition: color $transition-speed $transition-easing; 
	-ms-transition: color $transition-speed $transition-easing; 
	-o-transition: color $transition-speed $transition-easing; 
	transition: color $transition-speed $transition-easing; 

	&:hover,
	&:focus {
		outline: 0;
		color: $hover-color;
		-webkit-transition: color $transition-speed $transition-easing; 
		-moz-transition: color $transition-speed $transition-easing; 
		-ms-transition: color $transition-speed $transition-easing; 
		-o-transition: color $transition-speed $transition-easing; 
		transition: color $transition-speed $transition-easing; 
		cursor: pointer;
	}

	/* backward transition */
	&:before {
		-webkit-transition: all $transition-speed $transition-easing $delay-before;
		-moz-transition: all $transition-speed $transition-easing $delay-before;
		-ms-transition: all $transition-speed $transition-easing $delay-before;
		-o-transition: all $transition-speed $transition-easing $delay-before;
		transition: all $transition-speed $transition-easing $delay-before;
	}
	/* backward transition */
	&:after {
		-webkit-transition: all $transition-speed $transition-easing $delay-after;
		-moz-transition: all $transition-speed $transition-easing $delay-after;
		-ms-transition: all $transition-speed $transition-easing $delay-after;
		-o-transition: all $transition-speed $transition-easing $delay-after;
		transition: all $transition-speed $transition-easing $delay-after;
	}

	&:hover:after,
	&:focus:after  {
		outline: 0;
		background-color: $transition-color;
		-webkit-transition: all $transition-speed $transition-easing $delay-after;
		-moz-transition: all $transition-speed $transition-easing $delay-after;
		-ms-transition: all $transition-speed $transition-easing $delay-after;
		-o-transition: all $transition-speed $transition-easing $delay-after;
		transition: all $transition-speed $transition-easing $delay-after;
	}

	&:hover:before,
	&:focus:before {
		outline: 0;
		background-color: $transition-color;
		-webkit-transition: all $transition-speed $transition-easing $delay-before;
		-moz-transition: all $transition-speed $transition-easing $delay-before;
		-ms-transition: all $transition-speed $transition-easing $delay-before;
		-o-transition: all $transition-speed $transition-easing $delay-before;
		transition: all $transition-speed $transition-easing $delay-before;
	}
	
}

@mixin line-opt(
	$type,
	$width: $default-hover-width,
	$height:$default-hover-height,
	$transition-width: $max-width,
	$transition-height: $max-height) {

	@if $type == pseudoafter {
		&:after {
			content: "";
			position: absolute;
			z-index: -1;
			width: $width;
			height: $height;
		}

		&:hover:after,
		&:focus:after {
			width: $transition-width;
			height: $transition-height;
		}
	}

	@else if $type == pseudobefore {
		&:before {
			content: "";
			position: absolute;
			z-index: -1;
			width: $width;
			height: $height;
		}

		&:hover:before,
		&:focus:before  {
			width: $transition-width;
			height: $transition-height;
		}
	}
}