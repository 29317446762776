// ------------------------------------------------------------
// This file contains all styles related to the `footer` component.
// ------------------------------------------------------------

@include block('footer') {
  z-index: 1;
  padding-top: 120px;
  padding-bottom: 50px;
  background-color: transparent;
  border: 0;

  @media only screen and (max-height: 960px) {
    padding-top: 40px;
    padding-bottom: 30px;
  }

  @include respond-to(tablet) {
    padding-top: 50px;
  }

  @include respond-to(mobile) {
    padding-top: 50px;
  }

  background: url('../images/pattern-footer.png');
  background-repeat: no-repeat;
  background-position: bottom center;

  @include element('container') {
    max-width: $main-container-width;
    width: 100%;
    margin: 0 auto;
    @include modifier('with-padding') {
      padding: 0 $base_padding;
      @include respond-to(large-desktop) {
        max-width: $main-container-ultra-width;
        padding: 0 $base-padding-ultra;
      }
      @include respond-to('tablet') {
        padding-top: 50px;
        padding-bottom: 30px;
        padding-left: $base_padding_tablet;
        padding-right: $base_padding_tablet;
      }
      @include respond-to('tablet') {
        padding: 0 $base_padding_tablet;
      }
    }
  }

  @include element('columns') {
    @include grid-container(row, nowrap, center, space-between);
    @include respond-to(tablet) {
      @include grid-container(column);
    }
  }

  @include element('column') {
    @include grid-item();
    @include respond-to(tablet) {
      @include modifier('subscribe-column') {
        order: 1;
        margin-bottom: 50px;
      }
      @include modifier('copyright-column') {
        order: 2;
      }
      @include modifier('social-column') {
        order: 3;
        margin-top: 40px;
      }
      @include modifier('contact-us-column') {
        order: 4;
        margin-top: 50px;
      }
    }
  }

  @include element('copyright') {
    color: $color-gray;
    font-family: $font-stack;
    font-size: 0.875rem;
    line-height: 1;
    text-align: left;
    @include modifier('main-page') {
      margin-top: 5px;
    }
    @include respond-to(tablet) {
      text-align: center;
    }
  }

  @include element('contact-us') {
    color: $color-brown;
    font-family: $font-stack;
    font-size: 0.875rem;
    font-weight: bold;
    line-height: 1;
    text-align: left;
    margin-top: 37px;
    @include respond-to(tablet) {
      text-align: center;
    }
    & > a {
      color: $color-brown;
      text-decoration: none;
      cursor: pointer; /* hand-shaped cursor */
      @include transition-opt($color-brown, $color-brown, $default-hover-transition-speed, 0 0 7px 0);
    }
    @include modifier('main-page') {
      margin-top: 5px;
    }
    @include modifier('with-icon') {
      &:before {
        content: '';
        background: url('../images/contact.svg') no-repeat;
        width: 14px;
        height: 14px;
        display: block;
        float: left;
        margin-right: 10px;
        position: relative;
        @include respond-to(tablet) {
          display: inline-block;
          float: none;
          top: 2px;
        }
      }
    }
  }
}
