/* This stylesheet generated by Transfonter (https://transfonter.org) on May 17, 2017 5:18 PM */

@font-face {
  font-family: 'Exo 2';
  src: font('/Exo_2/Exo2-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Exo 2';
  src: font('/Exo_2/Exo2-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Exo 2 Semi';
  src: font('/Exo_2/Exo2-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Exo 2 Extra';
  src: font('/Exo_2/Exo2-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Exo 2';
  src: font('/Exo_2/Exo2-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Exo 2';
  src: font('/Exo_2/Exo2-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Exo 2';
  src: font('/Exo_2/Exo2-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Exo 2 Semi';
  src: font('/Exo_2/Exo2-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Exo 2';
  src: font('/Exo_2/Exo2-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Exo 2 Extra';
  src: font('/Exo_2/Exo2-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Exo 2';
  src: font('/Exo_2/Exo2-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Exo 2 Extra';
  src: font('/Exo_2/Exo2-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Exo 2';
  src: font('/Exo_2/Exo2-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Exo 2';
  src: font('/Exo_2/Exo2-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Exo 2';
  src: font('/Exo_2/Exo2-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Exo 2';
  src: font('/Exo_2/Exo2-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Exo 2';
  src: font('/Exo_2/Exo2-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Exo 2 Extra';
  src: font('/Exo_2/Exo2-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}
