// ------------------------------------------------------------
// This file contains all styles related to the `mobile burger` component.
// ------------------------------------------------------------

$burger_width: 21px;
$burger_height: 3px;

@include block('mobile_burger') {
  cursor: pointer;
  padding: 0;
  position: relative;
  span {
    top: 7px;
    cursor: pointer;
    @include border-radius();
    height: $burger_height;
    width: $burger_width;
    background: $color-brown;
    position: absolute;
    display: block;
    content: '';
    &:before, &:after {
      cursor: pointer;
      border-radius: 1px;
      height: $burger_height;
      width: $burger_width;
      background: $color-brown;
      position: absolute;
      display: block;
      content: '';
    }
    &:before {
      top: -6px;
    }
    &:after {
      bottom: -6px;
    }
    transition: all 500ms ease-in-out;
    &:before, &:after {
      transition: all 500ms ease-in-out;
    }
  }

  @include modifier('active') {
    span {
      background-color: transparent;
      &:before, &:after {
        top: 0;
      }
      &:before {
        transform: rotate(45deg);
      }
      &:after {
        transform: rotate(-45deg);
      }
    }
  }

  @include element('label-block') {
    display: block;
    position: relative;
    padding-left: 30px;
  }

  @include element('label') {
    display: none;
    @include modifier('show') {
      display: block;
    }
  }
}
