@mixin double-underline-opening {
  @include line-opt(pseudoafter, $transition-height: $default-hover-height);
  @include line-opt(pseudobefore, $transition-height: $default-hover-height);

 &:after {
  right: 0%; 
  bottom: 0;
  margin: 0 auto;
  left: 0; 
} 

&:before {
  right: 0%; 
  bottom: 10px;
  margin: 0 auto;
  left: 0;
} 
}