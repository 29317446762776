@mixin fill-left{
  @include line-opt(pseudobefore, $height: $max-height);
  &:hover {
  	color: $color !important;
  }

  &:before {
  top: 0%;
  right: 0;
  }
}