@mixin underline-opening {
  @include line-opt(pseudoafter, $transition-height: $default-hover-height);
  @include line-opt(pseudobefore, $transition-height: $default-hover-height);
    &:after {
        bottom: 0%;
        left: 0%;
        right: 0;
        margin: 0 auto;  
    }

    &:before {
        bottom: 0%;
        right: 0%; 
        left: 0;
        margin: 0 auto;
    }
}