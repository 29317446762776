@mixin bottom-right{
  @include line-opt(pseudoafter, $transition-height: $default-hover-height);
  @include line-opt(pseudobefore, $transition-height: $max-height, $transition-width: $default-hover-width);
  &:before {
      top: 0%;
      left: 0;
  }

  &:after {
      top: 0;
      left: 0; 
  }
}