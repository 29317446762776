// ------------------------------------------------------------
// This file contains all styles related to the `header` component.
// ------------------------------------------------------------

@include block('header') {
  background-color: $color-white;
  z-index: 2;

  @include modifier('transparent-bg') {
    background-color: transparent;
  }

  @include modifier('top') {
    .header__wrap {
      margin-top: 20px;
    }
    @media only screen and (max-height: 860px) {
      .header__wrap {
        margin-top: 15px;
      }
    }
    @include respond-to(tablet-landscape) {
      .header__wrap {
        margin-top: 0;
      }
    }
  }

  @include modifier('sidebar-open') {
    .sidebar {
      left: 0;
    }
    @include respond-to(mobile) {
      .header__container {
        position: fixed;
        top: 0;
        background: #fff;
        z-index: 9999;
        width: 100%;
      }
      .header__menu--global-menu-open {
        display: none;
      }
    }
  }

  @include respond-to(mobile) {
    background-color: $color-white;
    border-bottom: solid 1px rgba(156, 157, 157, 0.2);
  }

  @include element('fixed') {
    display: block;
    position: fixed;
    background: $color-white;
    z-index: 4444;
    .header__columns--main-menu {
      margin-top: 0;
    }
    @include modifier('show') {

    }
  }

  @include element('wrap') {
    width: 100%;
  }

  @include element('container') {
    max-width: $main-container-width;

    margin: 0 auto;
    padding: 0 $base_padding;
    border: 0;
    @include respond-to(large-desktop) {
      max-width: $main-container-ultra-width;
      padding: 0 $base-padding-ultra;
    }
    @include respond-to(tablet) {
      padding: 0 $base_padding_tablet;
    }
    @include respond-to(mobile) {
      padding: 0 $base_padding_mobile;
    }
  }

  @include element('columns') {
    @include grid-container(row, nowrap, center, space-between);
    height: $header-main-height;
  }

  @include element('column') {
    @include grid-item(3);
    text-align: center;

    &:first-child {
      @include grid-item(4.5);
      text-align: left;
    }

    &:last-child {
      @include grid-item(4.5);
      text-align: right;
    }

    @include modifier('logo') {
      img {
        max-width: 100%;
      }
    }
    @include modifier('logo-desctop') {
      @include respond-to('tablet-landscape') {
        display: none;
      }
    }
    @include modifier('logo-minimizied') {
      img {
        max-width: 57px;
      }
    }
    @include modifier('logo-mobile') {
      display: none;
      @include respond-to('tablet-landscape') {
        display: block;
      }
    }
  }

  @include element('column-grid') {
    li {
      list-style-type: none;
      display: inline-block;
      padding-right: 32px;
      height: 21px;
      color: #1a1a18;
      font-family: $font-stack;
      font-size: 1rem;
      font-weight: bold;
      line-height: 1;
      text-align: left;
      background: transparent;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
      &+li {
        border-left: solid 1px #ebebeb;
      }
      @include respond-to(mobile) {
        padding-right: 0;
      }
    }
    @include modifier('left') {
      li+li {
        padding-left: 30px;
      }
      @include respond-to(tablet-landscape) {
        li:nth-child(2) {
          display: none;
        }
      }
    }
    @include modifier('right') {
      @include respond-to(tablet-landscape) {
        li:first-child {
          display: none;
        }
        li+li {
          border-left: 0;
        }
      }
    }
  }

  @include element('menu') {
    cursor: pointer;
    font-family: $font-stack;
    font-size: 1rem;
    font-weight: bold;
    line-height: 1;
    text-align: left;
    color: #1a1a18;
    white-space: nowrap;
    span {
      @include transition-opt($color-brown, 'transparent', $default-hover-transition-speed, 0 0 2px 0);
    }
    &:before {
      content: '';
      display: block;
      float: left;
      margin-right: 14px;
      position: relative;
      top: 0;
    }
    @include modifier('open') {
      &:before {
        -webkit-mask-image: url('../images/burger.svg');
        mask-image: url('../images/burger.svg');
        background-color: $color-brown;
        width: 21px;
        height: 16px;
      }
    }
    @include modifier('close') {
      &:before {
        -webkit-mask-image: url('../images/close.svg');
        mask-image: url('../images/close.svg');
        background-color: $color-brown;
        width: 20px;
        height: 20px;
        top: -2px;
      }
    }
    @include modifier('mobile-burger-show') {
      display: none;
    }
    @include modifier('mobile-burger-label') {
      display: none;
    }
    @include respond-to(mobile) {
      &:before {
        margin-right: 8px;
      }
      @include modifier('mobile-burger-hide') {
        display: none;
      }
      @include modifier('mobile-burger-show') {
        display: block;
      }
    }
  }

  @include element('working-days') {
    font-family: $font-stack;
    font-size: 0.875rem;
    text-align: left;
    color: $font-color;
    font-weight: bold;
    span {
      font-weight: 300;
    }
    .day-separator {
      color: $color-brown;
    }
  }

  @include element('how-to-find') {
    a {
      color: $font-color;
      text-decoration: none;
      @include transition-opt($color-brown, $color-brown, $default-hover-transition-speed, 0 0 5px 0);
    }
    &:before {
      content: '';
      -webkit-mask-image: url('../images/location.svg');
      mask-image: url('../images/location.svg');
      background-color: $color-brown;
      width: 10px;
      height: 13px;
      display: block;
      float: left;
      margin-right: 9px;
      position: relative;
      top: 2px;
    }
  }

  @include element('languages-list') {
    position: relative;
    @include modifier('open') {
      .header__languages-list--selected {
        &:after {
          @include rotate(-180deg);
          transition: .3s;
        }
      }
    }

    @include modifier('selected') {
      padding-left: 30px;
      cursor: pointer;
      span {
        @include transition-opt($color-brown, 'transparent', $default-hover-transition-speed, 0);
      }
      &:after {
        content: '';
        background: url('../images/dropdown.svg') no-repeat;
        width: 10px;
        height: 8px;
        display: block;
        float: right;
        margin-left: 15px;
        position: relative;
        top: 5px;
        @include rotate(0deg);
        transition: .3s;
      }
      @include respond-to(mobile) {
        padding-left: 0;
        &:after {
          margin-left: 8px;
        }
      }
    }
    .dropdon-list {
      padding-left: 30px;
      display: none;
      max-width: 300px;
      width: 100%;
      border: 0;
      top: 44px;
      @include box-shadow(0 2px 5px 0 rgba(0, 0, 0, 0.1));
      position: absolute;
      background-color: $color-white;
      z-index: 9999;
      li {
        list-style-type: none;
        font-family: $font-stack;
        font-size: 1rem;
        line-height: 0;
        display: block;
        text-align: left;
        color: #1a1a18;
        font-weight: 400;
        padding: 17px 0;
        border-bottom: 1px solid rgba(156,157,157,0.2);
        border-left: 0;
        border-right: 0;
        border-top: 0;
        & > a {
          color: #1a1a18;
          text-decoration: none;
          -webkit-transition: color $default-hover-transition-speed $easing;
          -moz-transition: color $default-hover-transition-speed $easing;
          -ms-transition: color $default-hover-transition-speed $easing;
          -o-transition: color $default-hover-transition-speed $easing;
          transition: color $default-hover-transition-speed $easing;
          &:hover {
            outline: 0;
            font-weight: bold;
            color: $color-brown;
            -webkit-transition: color $default-hover-transition-speed $easing;
            -moz-transition: color $default-hover-transition-speed $easing;
            -ms-transition: color $default-hover-transition-speed $easing;
            -o-transition: color $default-hover-transition-speed $easing;
            transition: color $default-hover-transition-speed $easing;
            cursor: pointer; /* hand-shaped cursor */
          }
        }
        &:last-child {
          border-bottom: 0;
        }
      }
      @include respond-to(mobile) {
        padding-left: 0;
        li {
          padding-left: 10px;
          font-size: 0.875rem;
        }
      }
    }
  }
}
