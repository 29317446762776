@mixin left-down-right-up {
      @include line-opt(pseudoafter, $transition-width: $default-hover-width);
    @include line-opt(pseudobefore, $transition-width: $default-hover-width);

    &:after {
        top: 0%;
        left: 0%;
    }

    &:before {
        bottom: 0%;
        right: 0%; 
    }
}