// ------------------------------------------------------------
// This file contains all @font-face declarations.
// ------------------------------------------------------------

/* Proxima Nova */

@font-face {
  font-family: 'ProximaNova';
  font-weight: normal;
  font-style: normal;
  src: font("ProximaNova/proxima_nova-regular.eot");
  src: font("ProximaNova/proxima_nova-regular.eot") format("embedded-opentype"),
       font("ProximaNova/proxima_nova-regular.woff") format("woff"),
       font("ProximaNova/proxima_nova-regular.ttf") format("truetype");
}

@font-face {
  font-family: 'ProximaNova';
  font-weight: 600;
  font-style: normal;
  src: font("ProximaNova/proxima_nova-bold.eot");
  src: font("ProximaNova/proxima_nova-bold.eot") format("embedded-opentype"),
       font("ProximaNova/proxima_nova-bold.woff") format("woff"),
       font("ProximaNova/proxima_nova-bold.ttf") format("truetype");
}

@font-face {
  font-family: 'ProximaNova-Regular';
  src: font('/ProximaNova/ProximaNova-Regular.eot?#iefix') format('embedded-opentype'),
       font('/ProximaNova/ProximaNova-Regular.otf')  format('opentype'),
       font('/ProximaNova/ProximaNova-Regular.woff') format('woff'),
       font('/ProximaNova/ProximaNova-Regular.ttf')  format('truetype'),
       font('/ProximaNova/ProximaNova-Regular.svg#ProximaNova-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNova-Bold';
  src: font('/ProximaNova/ProximaNova-Bold.eot?#iefix') format('embedded-opentype'),
       font('/ProximaNova/ProximaNova-Bold.otf')  format('opentype'),
       font('/ProximaNova/ProximaNova-Bold.woff') format('woff'),
       font('/ProximaNova/ProximaNova-Bold.ttf')  format('truetype'),
       font('/ProximaNova/ProximaNova-Bold.svg#ProximaNova-Bold') format('svg');
  font-weight: 600;
  font-style: normal;
}