// ------------------------------------------------------------
// This file contains all styles related to the `info_block_page` module.
// ------------------------------------------------------------

@include block('info-block-page') {

  @include modifier('page2') {
    padding-top: 150px;
    @media only screen and (max-height: 860px) {
      padding-top: 70px;
    }
    @include respond-to(tablet-landscape) {
      padding-top: 70px;
    }
    @include respond-to(tablet) {
      padding-top: 50px;
    }
    @include respond-to(mobile) {
      padding-top: 0;
    }
  }

  @include modifier('inner') {
    padding-top: 90px;
    @media only screen and (max-height: 860px) {
      padding-top: 70px;
    }
    @include respond-to(tablet) {
      padding-top: 40px;
    }
    @include respond-to(mobile) {
      padding-top: 0;
    }
    .info-block-page__row + .info-block-page__row {
      margin-top: 90px;

      @media only screen and (max-height: 860px) {
        margin-top: 70px;
      }

      @include respond-to(tablet) {
        margin-top: 0;
      }
    }
  }

  @include element('row') {
    &+.info-block-page__row {
      margin-top: 140px;
      @media only screen and (max-height: 860px) {
        margin-top: 70px;
      }
      @include respond-to(tablet-landscape) {
        margin-top: 70px;
      }
      @include respond-to(tablet) {
        margin-top: 50px;
      }
      @include respond-to(mobile) {
        margin-top: 15px;
      }
    }
  }

  @include element('pagination') {
    padding-top: 130px;
    padding-bottom: 50px;
    @include respond-to(tablet) {
      padding-top: 60px;
    }
  }
}
