// ------------------------------------------------------------
// This file contains all styles related to the `contact details` component.
// ------------------------------------------------------------

@include block('contact-details') {
  @include grid-container();

  @include element('details-column') {
    @include grid-item(6);
    @include respond-to(tablet) {
      @include grid-item(12);
      @include modifier('working-hours-column') {
        order: 1;
      }
      @include modifier('company-info-column') {
        order: 1;
      }
    }

    ul {
      list-style-type: none;
      font-family: $font-stack;
      line-height: 2.29;
      text-align: left;
      li {
        color: $font-color;
        white-space: nowrap;
        & > a {
          color: $color-brown;
          text-decoration: none;
          @include transition-opt($color-brown, $color-brown, $default-hover-transition-speed, 0 0 7px 0);
        }
        span {
          font-weight: 300;
        }
      }
    }
  }

  @include element('icon') {
    &:before {
      content: '';
      background-color: $color-gray;
      float: left;
      position: relative;
      left: 0;
    }
    @include modifier('phone') {
      &:before {
        -webkit-mask-image: url('../images/phone.svg');
        mask-image: url('../images/phone.svg');
        width: 16px;
        height: 16px;
        top: 12px;
        margin-right: 20px;
      }
    }
    @include modifier('email') {
      &:before {
        -webkit-mask-image: url('../images/mail.svg');
        mask-image: url('../images/mail.svg');
        width: 18px;
        height: 12px;
        top: 13px;
        margin-right: 18px;
      }
    }
    @include modifier('web') {
      &:before {
        -webkit-mask-image: url('../images/globe.svg');
        mask-image: url('../images/globe.svg');
        width: 19px;
        height: 19px;
        top: 9px;
        margin-right: 18px;
      }
    }
  }

  @include modifier('floor') {
    margin-top: 15px;
    @include respond-to(mobile) {
      margin-top: 0;
    }
    .contact-details__details-column {
      ul {
        font-size: 0.875rem;
        font-weight: bold;
        @include respond-to(mobile) {
          font-size: 1rem;
        }
      }
      + .contact-details__details-column {
        padding-left: 15px;
      }
      @include respond-to(desktop) {
        @include grid-item(12);
        + .contact-details__details-column {
          padding-left: 0;
        }
        &.contact-details__details-column--working-hours-column {
          order: 1;
        }
        &.contact-details__details-column--company-info-column {
          order: 2;
          margin-top: 25px;
        }
      }
    }
  }

  @include modifier('text-page') {
    margin-top: 48px;
    .contact-details__details-column {
      @include respond-to(desktop) {
        @include grid-item(12);
        &.contact-details__details-column--working-hours-column {
          order: 1;
        }
        &.contact-details__details-column--company-info-column {
          order: 2;
          margin-top: 25px;
        }
      }
      ul {
        font-size: 1.125rem;
        line-height: 1.78;
        font-weight: bold;
        @include respond-to(tablet) {
          font-size: 1.375rem;
        }
      }
    }
  }
}