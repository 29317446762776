@mixin left-up-right-down {
      @include line-opt(pseudoafter, $transition-width: $default-hover-width);
    @include line-opt(pseudobefore, $transition-width: $default-hover-width);

    &:after {
        bottom: 0%;
        left: 0%;
    }

    &:before {
        top: 0%;
        right: 0%; 
    }
}