// ------------------------------------------------------------
// This file contains all styles related to the `sidebar` component.
// ------------------------------------------------------------

@include block('sidebar') {
  display: flex;
  flex-direction: column;
  left: -500px;
  max-width: 420px;
  width: 100%;
  position: fixed;
  top: -1px;
  bottom: -1px;
  z-index: 9999;
  background-color: $color-white;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 0;
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
  @include box-shadow(5px 0 10px 0 rgba(0, 0, 0, 0.16));

  @include respond-to('mobile') {
    max-width: 100%;
    top: 77px;
    border-top: 1px solid #ebebeb;
    @include box-shadow(none);
    .header__container {
      display: none;
    }
  }

  .header__container {
    padding-left: 0;
  }

  @include modifier('container') {
    padding-left: 60px;

    @include respond-to('tablet') {
      padding-left: $base_padding_tablet;
    }

    @include respond-to('mobile') {
      padding-left: 0
    }
  }

  @include element('nav-menu') {
    li {
      list-style-type: none;
      font-family: $font-stack;
      font-size: 1rem;
      font-weight: 300;
      line-height: 1;
      text-align: left;
      padding-top: 26px;
      padding-bottom: 23px;
      border-bottom: 1px solid rgba(156,157,157,0.2);
      &.empty-line {
        padding: 35px 0;
      }
      > a {
        color: $color-black;
        cursor: pointer; /* hand-shaped cursor */
        text-decoration: none;
        width: 100%;
        display: block;
        -webkit-transition: color $default-hover-transition-speed $easing;
        -moz-transition: color $default-hover-transition-speed $easing;
        -ms-transition: color $default-hover-transition-speed $easing;
        -o-transition: color $default-hover-transition-speed $easing;
        transition: color $default-hover-transition-speed $easing;
        &:hover {
          outline: 0;
          font-weight: bold;
          color: $color-brown;
          -webkit-transition: color $default-hover-transition-speed $easing;
          -moz-transition: color $default-hover-transition-speed $easing;
          -ms-transition: color $default-hover-transition-speed $easing;
          -o-transition: color $default-hover-transition-speed $easing;
          transition: color $default-hover-transition-speed $easing;
          cursor: pointer; /* hand-shaped cursor */
        }
        &:before {
          content: '';
          background-color: $color-brown;
          float: right;
          position: relative;
          right: 0;
          -webkit-mask-image: url('../images/dropdown.svg');
          mask-image: url('../images/dropdown.svg');
          width: 10px;
          height: 7px;
          top: 5px;
          margin-right: 47px;
          @include rotate(-90deg);
        }
        @include respond-to('mobile') {
          &:before {
            margin-right: 20px;
          }
        }
      }
      .active {
        font-weight: 600;
        &:hover {
          font-weight: $color-black;
        }
      }
    }
    @include respond-to('mobile') {
      padding-left: $base_padding_mobile;
    }
  }

  @include element('wrap') {
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    overflow: auto;
  }

  @include element('top') {
    flex: 1;
  }

  @include element('footer') {
    padding-bottom: 50px;
    padding-top: 30px;
  }

  @include element('footer-logo') {
    margin-bottom: 30px;
    @include respond-to('mobile') {
      display: none;
    }
  }

  @include element('working-days') {
    font-family: $font-stack;
    font-size: 0.875rem;
    line-height: 2.29;
    text-align: left;
    color: $font-color;
    font-weight: bold;
    > span {
      font-weight: 300;
    }
    @include respond-to('mobile') {
      text-align: center;
      line-height: 2;
    }
  }

  @include element('copyright') {
    margin-top: 25px;
    font-family: $font-stack;
    font-size: 0.875rem;
    line-height: 1;
    text-align: left;
    color: $color-gray;
    @include respond-to('mobile') {
      text-align: center;
    }
  }

  @include element('contact-us') {
    display: none;
    margin-top: 25px;
    color: $color-brown;
    font-family: $font-stack;
    font-size: 0.875rem;
    font-weight: bold;
    text-align: center;
    line-height: 1;
    & > a {
      color: $color-brown;
      text-decoration: none;
      cursor: pointer; /* hand-shaped cursor */
      @include transition-opt($color-brown, $color-brown, $default-hover-transition-speed, 0 0 7px 0);
    }
    @include modifier('with-icon') {
      &:before {
        content: '';
        background: url('../images/contact.svg') no-repeat;
        width: 14px;
        height: 14px;
        display: block;
        float: left;
        margin-right: 10px;
        position: relative;
        @include respond-to(mobile) {
          display: inline-block;
          float: none;
          top: 2px;
        }
      }
    }
    @include respond-to('mobile') {
      display: block;
    }
  }

  @include element('social') {
    display: none;
    @include respond-to('mobile') {
      text-align: center;
      margin-top: 25px;
      display: block;
    }
  }
}


