// ------------------------------------------------------------
// This file contains all styles related to the `text-rows` component.
// ------------------------------------------------------------

@include block('text-rows') {

  @include element('row') {
    border: 1px solid red;
    padding: 40px 0;
  }

  @include element('columns') {
    @include grid-container();

    @include modifier('vertical-align') {
      @include grid-container(row, wrap, center);
    }

    @include modifier('full') {
      .text-rows__column {
        @include grid-item(12);
        &:first-child,
        &:last-child {
          padding: 0;
        }
      }
      .column-content {
        padding-left: 155px;
        padding-right: 200px;
        @include respond-to(tablet-landscape) {
          padding-left: $base_padding;
        }
        @include respond-to(tablet) {
          padding: 30px;
        }
        @include respond-to(mobile) {
          padding: 20px;
        }
      }
    }

    @include modifier('left') {
      .column-content {
        padding-right: 155px;
        @include respond-to(tablet-landscape) {
          padding-right: $base_padding;
        }
        @include respond-to(tablet) {
          padding: 30px;
        }
        @include respond-to(mobile) {
          padding: 20px;
        }
      }
    }
    @include modifier('right') {
      .column-content {
        padding-left: 155px;
        @include respond-to(tablet-landscape) {
          padding-left: $base_padding;
        }
        @include respond-to(tablet) {
          padding: 30px;
        }
        @include respond-to(mobile) {
          padding: 20px;
        }
      }
    }
    @include modifier('only-text') {
      .text-rows__column {
        &:first-child {
          .column-content {
            padding-left: 155px;
            @include respond-to(tablet-landscape) {
              padding-left: $base_padding;
            }
            @include respond-to(tablet) {
              padding: 30px;
            }
            @include respond-to(mobile) {
              padding: 20px;
            }
          }
        }
        &:last-child {
          .column-content {
            padding-right: 155px;
            @include respond-to(tablet-landscape) {
              padding-right: $base_padding;
            }
            @include respond-to(tablet) {
              padding: 30px;
            }
            @include respond-to(mobile) {
              padding: 20px;
            }
          }
        }
        .description {
          margin-top: 0;
        }
      }
    }
    @include modifier('link') {
      &:first-child {
        padding-left: 150px;
      }
      &:last-child {
        padding-right: 100px;
      }
      .text-rows__column {
        &:first-child {
          @include grid-item(5);
          padding-right: 0;
        }
        &:last-child {
          @include grid-item(7);
          padding-left: 0;
        }
      }
      @include respond-to(tablet-landscape) {
        &:first-child {
          padding-left: $base_padding_tablet;
        }
        &:last-child {
          padding-right: $base_padding_tablet;
        }
        @include grid-container('column');
        .text-rows__column {
          &:first-child {
            @include grid-item(12);
            order: 1;
          }
          &:last-child {
            @include grid-item(12);
            order: 0;
            .main-image {
              max-width: 100%;
            }
          }
        }
      }
      @include respond-to(mobile) {
        &:first-child {
          padding-left: $base_padding_mobile;
        }
        &:last-child {
          padding-right: $base_padding_mobile;
        }
      }
    }
  }

  @include element('column') {
    @include grid-item(6);
    @include respond-to(tablet) {
      @include grid-item(12);
    }
    @include modifier('image') {
      @include respond-to('tablet') {
        order: 1;
      }
    }
    @include modifier('text') {
      @include respond-to('tablet') {
        order: 2;
      }
    }
    &:first-child {
      padding-right: 57px;
      @include respond-to(tablet-landscape) {
        padding-right: 25px;
      }
      @include respond-to(tablet) {
        padding-right: 0;
      }
    }
    &:last-child {
      padding-left: 57px;
      @include respond-to(tablet-landscape) {
        padding-left: 25px;
      }
      @include respond-to(tablet) {
        padding-left: 0;
      }
    }

    @include modifier('image-small-padding') {
      &:first-child {
        padding-right: 25px;
        @include respond-to(tablet) {
          padding-right: 0;
        }
      }
      &:last-child {
        padding-left: 25px;
        @include respond-to(tablet) {
          padding-left: 0;
        }
      }
    }

    .main-image {
      margin: 0;
      max-width: 684px;
      img {
        width: 100%;
        height: auto;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.4);
      }
      @include respond-to(large-desktop) {
        max-width: 100%;
      }
      @include respond-to(tablet-landscape) {
        img {
          display: block;
        }
      }
      @include respond-to(tablet) {
        margin: auto;
      }
    }
    .title {
      color: $font-color;
      font-family: $font-stack;
      font-size: 2rem;
      line-height: 1.19;
      text-align: left;
      &.title-uppercase {
        text-transform: uppercase;
      }
      &.title-brown {
        color: $color-brown;
        & > a {
          color: $color-brown;
        }
      }
      & > a {
        color: $font-color;
        text-decoration: none;
      }
      &.title-small {
        font-size: 1.75rem;
        line-height: 1.36;
      }
      &.title-medium {
        font-size: 2.25rem;
      }
      + .title {
        margin-top: 30px;
      }
      @include respond-to(mobile) {
        font-size: 2.125rem;
      }
    }
    .title ~ .description {
      margin-top: 20px;
    }

    .discount {
      float: right;
      margin-left: 10px;
      @include grid-container(row, wrap, center);
      width: 80px;
      height: 80px;
      &.discount-brown {
        background-color: $color-brown;
      }
      &.discount-round {
        @include border-radius(50%);
      }
      span {
        @include grid-item();
        display: block;
        margin: 0 auto;
        font-family: $font-stack;
        font-size: 1.75rem;
        font-weight: bold;
        line-height: 1.14;
        color: $color-white;
      }
    }

    .description {
      margin-top: 60px;
      width: 100%;
      font-family: $font-stack;
      font-size: 1.25rem;
      font-weight: 300;
      line-height: 1.30;
      text-align: left;
      color: $font-color;
      + .description {
        margin-top: 40px;
      }
      &.description-color-brown {
        color: $color-brown;
      }
      &.description-style-italic {
        font-style: italic;
        line-height: 1.31;
      }
      &.description-font-bold {
        font-weight: 500;
      }
      @include respond-to(tablet) {
        margin-top: 0;
      }
      a {
        color: $color-brown;
        @include transition-opt($color-brown, $color-brown, $default-hover-transition-speed, 0 0 5px 0);
        &.bold {
          font-weight: bold;
        }
      }
    }

    .m-t-0 {
      margin-top: 0;
    }

    .link {
      margin-top: 30px;
      display: block;
      font-size: 1.25rem;
      float: left;
      & > a {
        display: inline-block;
        font-family: $font-stack;
        font-weight: bold;
        line-height: 1.62;
        text-align: left;
        color: #986540;
        text-decoration: none;
        cursor: pointer;
        @include transition-opt($color-brown, $color-brown, $default-hover-transition-speed, 0 0 10px 0);
      }
      &:before {
        content: '';
        background: url('../images/go.svg') no-repeat;
        width: 10px;
        height: 14px;
        float: right;
        position: relative;
        top: 12px;
        left: 40px;
      }
    }
  }

  @include element('link-block') {
    position: absolute;
    width: 45%;
    max-width: 600px;
    margin-top: 5%;
    padding: 45px 50px;
    background-color: #ffffff;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.12);
    .title {
      font-family: $font-stack;
      font-size: 2rem;
      font-weight: bold;
      line-height: 1.38;
      text-align: left;
      color: $font-color;
    }
    a {
      margin-top: 30px;
      padding: 0 25px;
      display: block;
      max-width: 300px;
      width: 100%;
      height: 50px;
      background-color: #ffffff;
      border: solid 2px $color-brown;
      color: $color-brown;
      text-decoration: none;
      font-family: $font-stack;
      font-size: 1.25rem;
      font-weight: bold;
      line-height: 43px;
      &:after {
        content: '';
        background: url('../images/go.svg') no-repeat;
        width: 10px;
        height: 14px;
        display: block;
        float: right;
        margin-left: 15px;
        position: relative;
        top: 16px;
      }
    }
    @include respond-to(tablet-landscape) {
      position: relative;
      margin-top: 0;
      max-width: 100%;
      width: 100%;
      a {
        &:after {
          max-width: 100%;
        }
      }
    }
    @include respond-to(mobile) {
      padding: 25px 30px;
      .title {
        font-weight: 500;
      }
    }
  }

}
