// ------------------------------------------------------------
// This file contains all styles related to the `subscribe` component.
// ------------------------------------------------------------

.subscribe__field-block {
  overflow: hidden;
  position: relative;
  z-index: 1;
  display: inline-block;
  margin: 0;
  vertical-align: top;
  color: $font-color;
  font-family: $font-stack;
  font-weight: 600;
  font-size: 1.5rem;
  letter-spacing: 1px;
  text-align: left;
  padding-top: 4px;
  padding-bottom: 4px;
}
.subscribe__field {
  border: 0;
  height: 29px;
  width: 100%;
  outline: none;
  padding-left: 9px;
  padding-right: 50px;
  margin-bottom: 10px;
  @include placeholder {
    color: $font-color;
  }
}

.subscribe__field--hoshi {
  padding-bottom: 15px;
  background: transparent;
  color: #1a1a18;
  font-weight: 400;
}

.subscribe__field-line--hoshi {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0 0.25em;
  width: 100%;
  height: calc(100% - 1em);
  text-align: left;
  pointer-events: none;
}
.subscribe--filled .subscribe__label--hoshi {
  font-size: 0.625rem;
  letter-spacing: 1.7px;
}

.subscribe__field-line--hoshi {
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 10px);
    border-bottom: 2px solid transparentize($color-brown, .5);
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 10px);
    border-bottom: 1px solid #B9C1CA;
    margin-top: 0;
    border-bottom: 2px solid red;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    -webkit-transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
  }
}

.subscribe__field-line--hover-color:after {
  border-color: transparentize($color-brown, .1);
}

.subscribe__field--hoshi:focus + .subscribe__field-line--hoshi::after, .subscribe--filled .subscribe__field-line--hoshi::after {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

@include block('subscribe') {

  @include modifier('white') {
    .subscribe__title {
      color: #fff;
      font-weight: 500;
    }
    .subscribe__field {
      color: $color-white;
      @include placeholder {
        color: $color-white;
      }
    }
    .subscribe__field-line--hoshi {
      &::before {
        border-bottom: 2px solid transparentize($color-white, .5);
      }
    }
    .subscribe__field-line--hover-color:after {
      border-color: transparentize($color-white, .1);;
    }
    .subscribe__form-submit-btn {
      background: url('../images/send_white.svg') no-repeat;
    }
  }

  @include respond-to(tablet) {
    @include modifier('white') {
      .subscribe__title {
        color: $color-gray;
        font-weight: 500;
      }
      .subscribe__field {
        color: $font-color;
        @include placeholder {
          color: $font-color;
        }
      }
      .subscribe__field-line--hoshi {
        &::before {
          border-bottom: 2px solid transparentize($color-brown, .5);
        }
      }
      .subscribe__field-line--hover-color:after {
        border-color: transparentize($color-brown, .1);;
      }
      .subscribe__form-submit-btn {
        background: url('../images/send.svg') no-repeat;
      }
    }
  }

  width: 382px;
  @include element('title') {
    padding-left: 9px;
    color: $color-gray;
    font-family: $font-stack;
    font-size: 0.75rem;
    font-weight: bold;
    letter-spacing: 1.3px;
    text-transform: uppercase;
    @include respond-to('mobile') {
      margin-bottom: 15px;
      padding-left: 0;
      font-size: 0.875rem;
    }
  }
  @include respond-to(tablet) {
    padding: 0 50px;
    width: 100%;
    text-align: center;
    letter-spacing: 1.5px;
  }

  @include element('form') {
    position: relative;
    margin: 0;
    padding: 0;
  }

  @include element('form-submit-btn') {
    background: url('../images/send.svg') no-repeat;
    width: 24px;
    height: 23px;
    position: absolute;
    top: 8px;
    right: 0;
    display: block;
    border: 0;
    cursor: pointer; /* hand-shaped cursor */
    text-indent: -1000em;
    outline: none;
  }

  @include respond-to('mobile') {
    padding: 0;
  }
}
