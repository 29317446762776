@mixin move-up {
  @include line-opt(pseudoafter, $width: $max-width, $transition-height: $default-hover-height);
  
  &:after {
    bottom: 50%;
    left: 0; 
  }

  &:hover:after {
    bottom: 100%;
  }
}