@mixin move-left {
  @include line-opt(pseudoafter, $height: $max-height, $transition-width: $default-hover-width);
  
  &:after {
    top: 0;
    left: 50%;
  }

  &:hover:after {
    left: 0;
  }
}