// ------------------------------------------------------------
// This file contains all styles related to the `grid-pattern` component.
// ------------------------------------------------------------

@include block('grid-pattern') {
  background-image: url('../images/squares-big.png');
  background-repeat: no-repeat;
  position: fixed;
  background-position: top center;
  z-index: 0;
  width: 100%;
  height: 1000%;
}