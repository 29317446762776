@mixin move-down {
  @include line-opt(pseudoafter, $width: $max-width, $transition-height: $default-hover-height);
  
  &:after {
  	top: 50%;
  	left: 0; 
  }

  &:hover:after {
  	top: 100%;
  }
}