// ------------------------------------------------------------
// This file contains all styles related to the `body` component.
// ------------------------------------------------------------

@include block('body') {
  @include grid-container(column);

  position: relative;
  min-height: 100vh;
  background: $background-main-color;
  font-family: $font-stack;
  color: $font-color;
  overflow-x: hidden;

  /*
  background: url('../images/squares-big.png');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: top center;
  */
}

.owl-item img {
  width: 100%;
  height: auto;
}

/* include transition-opt mixin to initialize effects,
to get the basic styles for effects */