@mixin underline-closing {
    
 @include line-opt(pseudoafter, $transition-width: $half-width, $transition-height: $default-hover-height);
  @include line-opt(pseudobefore, $transition-width: $half-width, $transition-height: $default-hover-height);
    &:after {
        bottom: 0%;
        left: 0%;
    }

    &:before {
        bottom: 0%;
        right: 0%; 
    }
}