@mixin fill-right{
  @include line-opt(pseudobefore, $height: $max-height);
   &:hover {
  	color: $color !important;
  }
  
  &:before {
  top: 0%;
  left: 0;
  }
}