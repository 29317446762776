// ------------------------------------------------------------
// This file contains all project-wide mixins.
// ------------------------------------------------------------

// BEM - block
@mixin block($block) {
  .#{$block} {
    @content;
  }
}

// BEM - element
@mixin element($element) {
  $index: str-index('#{&}', '--');

  @if $index {
    $block: str-slice('#{&}', 1, $index - 1);

    #{$block}__#{$element} {
      @content;
    }
  } @else {
    &__#{$element} {
      @content;
    }
  }
}

// BEM - modifier
@mixin modifier($modifier) {
  &--#{$modifier} {
    @content;
  }
}

// Flexbox grid `container` properties
@mixin grid-container($flex-direction: row, $flex-wrap: wrap, $align-items: stretch, $justify-content: flex-start) {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  -webkit-box-flex: 0;
  flex: 0 1 auto;
  -webkit-flex-direction: $flex-direction;
  -ms-flex-direction: $flex-direction;
  flex-direction: $flex-direction;
  -webkit-flex-wrap: $flex-wrap;
  -ms-flex-wrap: $flex-wrap;
  flex-wrap: $flex-wrap;
  -webkit-align-items: $align-items;
  -ms-flex-align: $align-items;
  align-items: $align-items;
  -webkit-justify-content: $justify-content;
  justify-content: $justify-content;
}

// Flexbox grid `item` properties
@mixin grid-item($number: auto, $order: 0, $flex: 0 0 auto) {
  $width: auto;

  @if type-of($number) == number {
    $width: (100/12) * $number * 1%;
    max-width: $width;
  }

  box-sizing: border-box;
  -webkit-flex: $flex;
  -ms-flex: $flex;
  flex: $flex;
  -webkit-flex-basis: $width;
  -ms-flex-preferred-size: $width;
  flex-basis: $width;
  -webkit-order: $order;
  -ms-flex-order: $order;
  order: $order;
}

// Flexbox grid `item` reset
@mixin grid-item-reset($important: false) {
  @if $important == true {
    max-width: 100% !important;
    -webkit-flex: 0 0 auto !important;
    -ms-flex: 0 0 auto !important;
    -webkit-box-flex: 0 !important;
    flex: 0 0 auto !important;
    -webkit-flex-basis: 0 !important;
    -ms-flex-preferred-size: 0 !important;
    flex-basis: 0 !important;
  } @else {
    max-width: 100%;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    -webkit-box-flex: 0;
    flex: 0 0 auto;
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
  }
}

// Responsive breakpoint manager
// Breakpoints are defined in `variables`.
@mixin respond-to($breakpoint) {
  $raw-query: map-get($breakpoints, $breakpoint);

  @if $raw-query {
    $query: if(type-of($raw-query) == 'string', unquote($raw-query), inspect($raw-query));

    @media only screen and #{$query} {
      @content;
    }
  } @else {
    @error 'No value found for `#{$breakpoint}`. ' + 'Please make sure it is defined in `$breakpoints` map.';
  }
}

// Mixin for `placeholder` manipulation
@mixin placeholder() {
  &::-webkit-input-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }
}