// ------------------------------------------------------------
// This file contains all styles related to the `slider` component.
// ------------------------------------------------------------

@include block('slider') {

  @include element('wrap') {
    position: relative;
    margin: 0 auto;
    padding: 2.5rem 2rem;
    max-width: calc(1440px - 230px);
    @include respond-to(large-desktop) {
      max-width: calc(1640px - 130px);
    }

    @include respond-to(mobile) {
      padding: 2rem 1.5rem;
      max-width: 100vw;
    }
  }

  @include element('view') {
    display: none;

    @include modifier('product') {
      display: block;

      .slider__wrap {
        padding: 20px 50px;

        .owl-stage-outer {
          position: relative;
          height: 100%;
          overflow: hidden;
          -webkit-transform: translate3d(0px, 0px, 0px);

          .owl-stage {
            position: relative;
            height: 100%;
            -ms-touch-action: pan-Y;
            -moz-backface-visibility: hidden;

            &:after {
              content: ".";
              display: block;
              clear: both;
              visibility: hidden;
              line-height: 0;
              height: 0;
            }

            .owl-item {
              -webkit-backface-visibility: hidden;
              -moz-backface-visibility: hidden;
              -ms-backface-visibility: hidden;
              -webkit-transform: translate3d(0, 0, 0);
              -moz-transform: translate3d(0, 0, 0);
              -ms-transform: translate3d(0, 0, 0);
              position: relative;
              padding: .625rem;
              height: 100%;
              min-height: 1px;
              float: left;
              -webkit-backface-visibility: hidden;
              -webkit-tap-highlight-color: transparent;
              -webkit-touch-callout: none;

              .slider__product-item {
                height: 100%;
              }
            }
          }
        }

        .owl-nav {
          &.disabled {
            display: none;
          }

          div {
            position: absolute;
            top: 50%;
            display: block;
            text-decoration: none;
            opacity: 0.6;
            cursor: pointer;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            transform: translateY(-50%);
            border: 0;
            @include border-radius(2rem);
            text-align: center;

            &:before {
              content: '';
              width: 18px;
              height: 25px;
              display: block;
              color: #9d9ea0;
            }

            &:hover {
              opacity: 1;
            }

            &:first-child {
              left: 20px;
              padding: .5rem 0.125rem .5rem 0;

              &:before {
                background: url('../images/left.svg') no-repeat;
              }
            }

            &:last-child {
              right: 15px;
              padding: .5rem 0 .5rem 0.125rem;

              &:before {
                background: url('../images/right.svg') no-repeat;
              }
            }
          }
        }

        .owl-dots {
          &.disabled {
            display: none;
          }

          .owl-dot {
            cursor: pointer;
            cursor: hand;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
          }
        }

        &.owl-loaded {
          display: block;
        }

        &.owl-loading {
          opacity: 0;
          display: block;
        }

        &.owl-hidden {
          opacity: 0;
        }

        &.owl-refresh .owl-item {
          visibility: hidden;
        }

        &.owl-drag .owl-item {
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
        }

        &.owl-grab {
          cursor: move;
          cursor: grab;
        }
      }
    }

    @include modifier('product2') {
      display: block;

      .slider__wrap {

        /*
        padding: 20px 50px;
        */
        padding: 0;
        .owl-stage-outer {
          position: relative;
          height: 100%;
          overflow: hidden;
          -webkit-transform: translate3d(0px, 0px, 0px);

          .owl-stage {
            position: relative;
            height: 100%;
            -ms-touch-action: pan-Y;
            -moz-backface-visibility: hidden;

            &:after {
              content: ".";
              display: block;
              clear: both;
              visibility: hidden;
              line-height: 0;
              height: 0;
            }

            .owl-item {
              -webkit-backface-visibility: hidden;
              -moz-backface-visibility: hidden;
              -ms-backface-visibility: hidden;
              -webkit-transform: translate3d(0, 0, 0);
              -moz-transform: translate3d(0, 0, 0);
              -ms-transform: translate3d(0, 0, 0);
              position: relative;
              /*
              padding: .625rem;
              */
              padding: 1px;
              height: 100%;
              min-height: 1px;
              float: left;
              -webkit-backface-visibility: hidden;
              -webkit-tap-highlight-color: transparent;
              -webkit-touch-callout: none;

              .slider__product-item {
                height: 100%;
              }
            }
          }
        }

        .owl-nav {
          &.disabled {
            display: none;
          }

          @include respond-to(tablet) {
            display: none;
          }

          div {
            position: absolute;
            top: 49%;
            display: block;
            text-decoration: none;
            opacity: 0.6;
            cursor: pointer;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            transform: translateY(-50%);
            border: 0;
            @include border-radius(2rem);
            text-align: center;

            &:before {
              content: '';
              width: 18px;
              height: 25px;
              display: block;
              color: #9d9ea0;
            }

            &:hover {
              opacity: 1;
            }

            &:first-child {
              left: -50px;
              padding: .5rem 0.125rem .5rem 0;

              &:before {
                background: url('../images/left.svg') no-repeat;
              }
            }

            &:last-child {
              right: -50px;
              padding: .5rem 0 .5rem 0.125rem;

              &:before {
                background: url('../images/right.svg') no-repeat;
              }
            }

            @include respond-to(tablet) {
              &:first-child {
                left: 20px;
              }

              &:last-child {
                right: 15px;
              }
            }
          }
        }

        .owl-dots {
          position: relative;
          top: -50px;
          text-align: center;
          -webkit-tap-highlight-color: transparent;
          &.disabled {
            display: none;
          }

          @include respond-to('tablet') {
            top: 20px;
            padding-bottom: 20px;
          }

          .owl-dot {
            cursor: pointer;
            cursor: hand;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            display: inline-block;
            zoom: 1;
            &.active span {
              background: $color-white;
              @include respond-to(tablet) {
                background: $color-brown;
              }
            }
            span {
              width: 18px;
              height: 18px;
              margin: 5px 7px;
              background: transparent;
              display: block;
              -webkit-backface-visibility: visible;
              transition: opacity .2s ease;
              border: 2px solid $color-white;
              border-radius: 0;
              @include respond-to(tablet) {
                border: 2px solid transparentize($color-brown, .5);
              }
            }
          }
        }

        &.owl-loaded {
          display: block;
        }

        &.owl-loading {
          opacity: 0;
          display: block;
        }

        &.owl-hidden {
          opacity: 0;
        }

        &.owl-refresh .owl-item {
          visibility: hidden;
        }

        &.owl-drag .owl-item {
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
        }

        &.owl-grab {
          cursor: move;
          cursor: grab;
        }
      }
    }

    @include modifier('slider-half-right') {
      .slider__wrap {
        .owl-dots {
          text-align: left;
          left: 12%;
          .owl-dot {
            &.active span {
              background: $color-brown;
            }
            span {
              border: 2px solid transparentize($color-brown, .5);
            }
          }
        }
        @media only screen and (max-height: 860px) {
          .owl-dots {
            text-align: right;
            right: 12%;
            left: auto;
            .owl-dot {
              &.active span {
                background: $color-white;
              }
              span {
                border: 2px solid transparentize($color-white, .5);
              }
            }
          }
        }
      }

      .slider__main-item {
        position: initial;
        .image {
          float: right;
          width: 70%;

          background-size: cover!important;
          background-repeat: no-repeat!important;
          background-position: center top!important;
          img {
            visibility: hidden;
          }

          @media only screen and (max-height: 860px) {
            height: 40vh;
            width: 100%;
          }
          @include respond-to(tablet) {
            float: none;
            width: 100%;
          }
        }
      }
      @include respond-to(tablet) {
        .slider__wrap {
          .owl-dots {
            text-align: center;
            left: auto;
          }
        }
      }
    }

    @include modifier('active') {
      display: block;
    }
  }

  @include element('product-item') {
    border: 0;
    background-color: $color-white;
    box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.08);
  }

  @include element('product-item-content') {
    & + .slider__product-item-content {
      border-top: 1px solid rgba(236, 236, 236, 0.3);
    }
    padding: 20px;
    .product-item-brand {
      text-align: center;
      margin-bottom: 15px;
      img {
        max-width: 70px;
        width: 100%;
      }
    }
    .product-item-image {
      img {
        width: 100%;
        height: auto;
      }
    }
    .product-item-title {
      margin-top: 15px;
      color: $font-color;
      font-family: $font-stack;
      font-size: 18px;
      text-align: center;
      & > a {
        text-decoration: none;
        color: $font-color;
      }
    }
    .product-item-price {
      color: $color-red;
      font-family: $font-stack;
      margin-top: 23px;
      font-size: 20px;
      font-weight: 700;
      text-align: center;
    }
  }

  @include element('product') {
    display: none;
    h2 {
      margin-bottom: 50px;
      display: block;
      padding: 0 5rem;
      font-family: $font-stack;
      font-weight: 400;
      font-size: 24px;
      line-height: 1.33;
      letter-spacing: 1px;
      text-align: center;
      color: $color-gray;
      text-transform: uppercase;
      @include respond-to('tablet') {
        padding: 0 $base_padding_tablet;
      }
      @include respond-to('mobile') {
        padding: 0 $base_padding_mobile;
        margin-bottom: 5px;
        font-size: 20px;
        line-height: 0.89;
        letter-spacing: 1.5px;
        text-align: center;
      }
    }
  }

  @include element('main-item') {
    width: 100%;
    max-width: 1167px;
    margin: 0 auto;
    position: relative;
    border: 0;
    padding: 0;
    .content {
      width: 100%;
      .image {
        background-size: cover!important;
        background-repeat: no-repeat!important;
        background-position: center top!important;
        img {
          visibility: hidden;
        }
      }
      /*
      img {
        width: 100%;
        height: auto;
      }
      */
    }
    @include respond-to(large-desktop) {
      max-width: 1367px;
    }
    @include respond-to(tablet) {
      height: auto;
    }

    @media only screen and (max-height: 960px) {
      .content img {
        height: 40vh;
      }
    }
  }
}