//---------------------------------------------------
//  SCSS Prefixer
//---------------------------------------------------

// Border

// border-radius

@mixin border-radius($radius: 1px) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  -o-border-radius: $radius;
  border-radius: $radius;
}

@mixin border-top-left-radius($radius: 1px) {
  -webkit-border-top-left-radius: $radius;
  -moz-border-top-left-radius: $radius;
  -ms-border-top-left-radius: $radius;
  -o-border-top-left-radius: $radius;
  border-top-left-radius: $radius;
}

@mixin border-top-right-radius($radius: 1px) {
  -webkit-border-top-right-radius: $radius;
  -moz-border-top-right-radius: $radius;
  -ms-border-top-right-radius: $radius;
  -o-border-top-right-radius: $radius;
  border-top-right-radius: $radius;
}

@mixin border-bottom-left-radius($radius: 1px) {
  -webkit-border-bottom-left-radius: $radius;
  -moz-border-bottom-left-radius: $radius;
  -ms-border-bottom-left-radius: $radius;
  -o-border-bottom-left-radius: $radius;
  border-bottom-left-radius: $radius;
}

@mixin border-bottom-right-radius($radius: 1px) {
  -webkit-border-bottom-right-radius: $radius;
  -moz-border-bottom-right-radius: $radius;
  -ms-border-bottom-right-radius: $radius;
  -o-border-bottom-right-radius: $radius;
  border-bottom-right-radius: $radius;
}

// Box Shadows

@mixin box-shadow($args) {
  -webkit-box-shadow: $args;
  box-shadow: $args;
  -moz-box-shadow: $args;
}

@mixin inner-shadow($args) {
  @include box-shadow (inset $args);
}

// Box Sizing

@mixin box-sizing($args) {
  -webkit-box-sizing: $args;
  -moz-box-sizing: $args;
  box-sizing: $args;
}

@mixin border-box() {
  @include box-sizing(border-box);
}

@mixin content-box() {
  @include box-sizing(content-box);
}

// Generic transform
@mixin transform($transforms) {
  -moz-transform: $transforms;
  -o-transform: $transforms;
  -ms-transform: $transforms;
  -webkit-transform: $transforms;
  transform: $transforms;
}
// Rotate
@mixin rotate($deg) {
  @include transform(rotate($deg));
}