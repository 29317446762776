// ------------------------------------------------------------
// This file contains all styles related to the `main-subscribe` component.
// ------------------------------------------------------------

@include block('main-subscribe') {
  position: absolute;

  .main-subscribe__title {
    max-width: 420px;
    font-size: 2rem;
    font-weight: bold;
    line-height: 1.38;
  }

  @include modifier('white-bg') {
    background-color: #ffffff;
    @include box-shadow(0 0 10px 0 rgba(0, 0, 0, 0.12));

    .main-subscribe__title {
      color: $font-color;
    }

    .subscribe {
      margin-top: 35px;
    }
    .subscribe__title {
      font-size: 1rem;
      letter-spacing: 1.3px;
    }

    @include respond-to(tablet) {

      @include box-shadow(none);
      .main-subscribe__title {
        color: $font-color;
        max-width: 100%;
        font-weight: 500;
      }

      .subscribe__title {
        color: $color-gray;
        max-width: 100%;
        font-weight: 600;
      }
    }

    @include respond-to(mobile) {
      .main-subscribe__title {
        font-weight: 400;
      }
    }
  }

  @include modifier('top') {
    top: 10%;
  }

  @include modifier('transparent-bg') {

    .main-subscribe__title {
      color: $color-white;
    }

    .subscribe {
      margin-top: 35px;
    }
    .subscribe__title {
      font-size: 1rem;
      letter-spacing: 1.3px;
      color: transparentize($color-white, .65);
    }
    .subscribe__form-field {
      background-color: transparent;
      color: $color-white;
      @include placeholder {
        color: $color-white;
      }
    }
    .subscribe__form-submit-btn {
      -webkit-mask-image: url('../images/send.svg');
      mask-image: url('../images/send.svg');
      background-color: $color-white;
    }

    @include respond-to(tablet) {
      background-color: #ffffff;
      /*
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.12);
      */

      .main-subscribe__title {
        color: $font-color;
        max-width: 100%;
        font-weight: 500;
      }

      .subscribe__title {
        color: $color-gray;
        max-width: 100%;
        font-weight: 600;
      }
    }

    @include respond-to(mobile) {
      .main-subscribe__title {
        font-weight: 400;
      }
    }
  }

  @include modifier('content-n1') {
    padding: 50px 70px;

    @media only screen and (max-height: 860px) {
      padding-top: 25px;
      padding-bottom: 25px;
    }

    @include respond-to(tablet) {
      padding: 30px;
    }
  }

  @include element('title') {
    width: 100%;
    @include respond-to(tablet) {
      text-align: center;
      margin: 0 auto;
    }
  }

  @include respond-to(tablet) {
    position: relative;
    @include modifier('white-bg') {
      @include box-shadow('none');
    }
  }

}
