@mixin linethrough-closing {
    @include line-opt(pseudoafter, $default-hover-width, $linethrough-height, $half-width, $linethrough-height);
    @include line-opt(pseudobefore, $default-hover-width, $linethrough-height, $half-width, $linethrough-height);

  &:after {
    left: 0;
    top: 50%; 
    z-index: 9; 
  }

    &:before {
        top: 50%;
        right: 0%; 
        z-index: 9; 
    }
}