// ------------------------------------------------------------
// This file contains all styles related to the `contacts_page` module.
// ------------------------------------------------------------

@include block('contact-page') {

  @include element('title-block') {
    padding: 60px 0;
    h2 {
      font-family: $font-stack;
      font-size: 2rem;
      line-height: 1.28;
      text-align: center;
      color: #1a1a18;
      font-weight: 500;
    }
    @include modifier('description') {
      max-width: 700px;
      width: 100%;
      margin:auto;
      margin-top: 6px;
      font-family: $font-stack;
      font-size: 1.25rem;
      font-weight: 300;
      line-height: 1.33;
      text-align: center;
      color: #1a1a18;
      @include respond-to(mobile) {
        margin-top: 25px;
      }
    }
    @include respond-to(tablet) {
      padding: 30px 0;
    }
  }

  @include element('grid') {
    @include grid-container(row, wrap, center);
  }

  @include element('grid-column') {
    @include grid-item(6);
    @include respond-to(tablet) {
      @include grid-item(12);
      margin-top: 10px;
    }
    &:first-child {
      padding-right: 40px;
      @include respond-to(tablet) {
        padding-right: 0;
      }
    }
    &:last-child {
      padding-left: 40px;
      @include respond-to(tablet) {
        padding-left: 0;
        margin-top: 50px;
        margin-bottom: 20px;
      }
    }
  }

  @include respond-to(tablet) {
    @include element('grid-column') {
      & + .contact__grid-column {
        margin-top: 50px;
      }
    }
  }

}
