@import 'base';
@import 'defaults';

@import 'effects/overline/overline-right';
@import 'effects/overline/overline-left';
@import 'effects/overline/overline-closing';
@import 'effects/overline/overline-opening';

@import 'effects/underline/underline-left';
@import 'effects/underline/underline-closing';
@import 'effects/underline/underline-opening';
@import 'effects/underline/underline-right';

@import 'effects/both-side/both-right';
@import 'effects/both-side/both-opening-horizontal';
@import 'effects/both-side/both-up';
@import 'effects/both-side/both-down';
@import 'effects/both-side/both-left';
@import 'effects/both-side/left-up-right-down';
@import 'effects/both-side/left-down-right-up';
@import 'effects/both-side/overline-left-underline-right';
@import 'effects/both-side/overline-right-underline-left';

@import 'effects/fill/fill-up';
@import 'effects/fill/fill-down';
@import 'effects/fill/fill-left';
@import 'effects/fill/fill-right';
@import 'effects/fill/fill-opening-horizontal';
@import 'effects/fill/fill-closing-horizontal';
@import 'effects/fill/fill-closing-vertical';

@import 'effects/fill-corners/fill-top-left';
@import 'effects/fill-corners/fill-top-right';
@import 'effects/fill-corners/fill-bottom-left';
@import 'effects/fill-corners/fill-bottom-right';

@import 'effects/corners-close/left-top';
@import 'effects/corners-close/right-top';
@import 'effects/corners-close/left-bottom';
@import 'effects/corners-close/right-bottom';

@import 'effects/corners-expand/top-right';
@import 'effects/corners-expand/top-left';
@import 'effects/corners-expand/bottom-left';
@import 'effects/corners-expand/bottom-right';

@import 'effects/one-side/left-down';
@import 'effects/one-side/left-up';
@import 'effects/one-side/right-up';
@import 'effects/one-side/right-down';

@import 'effects/linethrough/linethrough-opening';
@import 'effects/linethrough/linethrough-closing';
@import 'effects/linethrough/linethrough-left';
@import 'effects/linethrough/linethrough-right';

@import 'effects/double-underline/double-underline-left';
@import 'effects/double-underline/double-underline-right';
@import 'effects/double-underline/double-underline-opening';
@import 'effects/double-underline/double-underline-left-right';
@import 'effects/double-underline/double-underline-right-left';

@import 'effects/double-linethrough/double-linethrough-left';
@import 'effects/double-linethrough/double-linethrough-right';
@import 'effects/double-linethrough/double-linethrough-left-right';
@import 'effects/double-linethrough/double-linethrough-right-left';
@import 'effects/double-linethrough/double-linethrough-opening';

@import 'effects/moves/move-down';
@import 'effects/moves/move-up';
@import 'effects/moves/move-right';
@import 'effects/moves/move-left';
@import 'effects/moves/move-vertical';
@import 'effects/moves/move-horizontal';
