@mixin double-linethrough-opening {
   @include line-opt(pseudoafter, $height: $linethrough-height, $transition-height: $linethrough-height);
  @include line-opt(pseudobefore, $height: $linethrough-height, $transition-height: $linethrough-height);

  &:after {
    left: 0;
    top: 54%; 
    z-index: 9; 
      right: 0;
  margin: 0 auto;
  }

  &:before {
    left: 0;
    top: 44%; 
    z-index: 9; 
      right: 0;
  margin: 0 auto;
  }
}