/* defaults for transition-opt */
$lightblue:#1B98E0;
$darkblue: #2C3E50;

$default-hover-color: $lightblue;
$default-hover-display: inline;
$default-hover-transition-color: $lightblue;
$default-hover-transition-speed: 0.3s;
$easing: ease;
$without-delay: 0s;
$default-hover-padding: 10px;

/* defaults for line-opt */
$default-hover-width: 3px;
$default-hover-height: 2px;
$max-height: 100%;
$max-width: 100%;

/* defaults for transitions */
$half-width: 50%;
$half-height: 50%;
$small-height: 1px;
$linethrough-height: 5px;

/* default delay */
$default-delay: 2s;

/* defaults for typograhpy element */
$color: $darkblue; /* color when element is NOT hovered. */