@mixin double-underline-right {
  @include line-opt(pseudoafter, $transition-height: $default-hover-height);
  @include line-opt(pseudobefore, $transition-height: $default-hover-height);
  
 &:after {
  left: 0%; 
  bottom: 0;
} 

&:before {
  left: 0%; 
  bottom: 10px;
} 

}