// ------------------------------------------------------------
// Korso front.
// Crafted with love by Viktors Golubevs
// ------------------------------------------------------------

// Configuration, mixins and functions
@import 'abstract/variables';
@import 'abstract/elements';
@import 'abstract/functions';
@import 'abstract/mixins';

// Vendor stylesheets
@import 'vendor/normalize';
@import 'vendor/fontawesome';
@import 'vendor/exo2';
@import 'vendor/animate';
@import 'vendor/input';
@import 'vendor/tooltipster';
@import 'vendor/perfect_scrollbar';
@import 'vendor/owl_carousel.scss';

@import 'vendor/sass/css-classes';


// Base configuration and styles
@import 'base/base';
@import 'base/fonts';

// Components
@import 'components/body';
@import 'components/header';
@import 'components/sub_header';
@import 'components/footer';
@import 'components/form';
@import 'components/map';
@import 'components/form';
@import 'components/main';
@import 'components/navigation_icons';
@import 'components/text-rows';
@import 'components/slider';
@import 'components/pagination';
@import 'components/sidebar';
@import 'components/main-subscribe';
@import 'components/subscribe';
@import 'components/mobile_burger';
@import 'components/social_links';
@import 'components/grid_pattern';
@import 'components/floor_map';
@import 'components/contact_details';

// Modules
@import 'modules/_contact_page';
@import 'modules/_info_block_page';
@import 'modules/_main_page';
