// ------------------------------------------------------------
// This file contains all styles related to the `sub_header` component.
// ------------------------------------------------------------

@include block('sub-header') {
  background-color: $color-white;
  @include box-shadow(0 6px 4px -4px rgba(0, 0, 0, 0.1));
  @include respond-to(mobile) {
    @include box-shadow(none);
  }

  @include element('container') {
    max-width: $main-container-width;
    width: 100%;
    margin: 0 auto;
    padding: 0 $base_padding;
    border: 0;
    @include respond-to(large-desktop) {
      max-width: $main-container-ultra-width;
      padding: 0 $base-padding-ultra;
    }
    @include respond-to(tablet) {
      padding: 0;
    }
  }

  @include element('grid') {
    @include grid-container(row, wrap, center, flex-start);
    height: 67px;
    @include respond-to(tablet) {
      @include grid-container(column, wrap, center, flex-start);
      height: auto;
    }
  }

  @include element('grid-column') {
    @include modifier('left') {
      @include grid-item(4.5);
      @include respond-to(tablet-landscape) {
        @include grid-item(5);
      }
      @include respond-to(tablet) {
        @include grid-item(12);
        width: 100%;
        border-bottom: 1px solid rgba(156, 157, 157, 0.2);
        height: 50px;
        padding: 0 $base_padding_tablet;
      }
      @include respond-to(mobile) {
        padding: 0;
      }
    }

    @include modifier('one') {
      @include grid-item(2);
      .sub-header__left-col-grid {
        .sub-header__left-col-grid-column {
          @include grid-item(12);
        }
      }
      @include respond-to(tablet) {
        @include grid-item(12);
      }
    }

    @include modifier('mobile-with-padding') {
      @include respond-to(mobile) {
        padding: 0 $base_padding_mobile;
      }
    }

    @include modifier('right') {
      @include grid-item(7.5);
      @include respond-to(tablet-landscape) {
        @include grid-item(7);
      }
      @include respond-to(tablet) {
        @include grid-item(12);
      }
    }

    @include respond-to(mobile) {
      @include grid-item(12);
    }
  }

  @include element('left-col-grid') {
    @include grid-container(row, nowrap);
  }

  @include element('left-col-grid-column') {
    font-family: $font-stack;
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.13;
    text-align: left;
    color: #1a1a18;
    border-right: 1px solid #ebebeb;

    @include respond-to(tablet) {
      position: relative;
      top: 15px;
      &:last-child {
        border-right: 0;
      }
    }
    @include respond-to(mobile) {
      border-right: 0;
    }
    @include modifier('left') {
      @include grid-item(4);
      @include respond-to(tablet) {
        @include grid-item(3);
      }
      @include respond-to(mobile) {
        display: none;
        &.sub-header__left-col-grid-column--not-hide {
          display: block;
          @include grid-item(12);
        }
      }
    }

    @include modifier('one-full') {
      @include grid-item(12);
      border-right: 0;
    }

    @include modifier('right') {
      @include grid-item(8);
      @include respond-to(tablet) {
        @include grid-item(9);
      }
      @include respond-to(mobile) {
        @include grid-item(12);
        text-align: center;
      }
    }
  }

  @include element('dropdown') {
    background-color: transparent;
    position: relative;
    .dropdown-title {
      cursor: pointer;
      padding-left: 30px;
      padding-right: 30px;
      white-space: nowrap;
      span {
        @include transition-opt($color-brown, 'transparent', $default-hover-transition-speed, 0);
      }
      @include respond-to(tablet-landscape) {
        padding-right: 15px;
      }
      &:after {
        content: '';
        background: url('../images/dropdown.svg') no-repeat;
        width: 10px;
        height: 8px;
        display: block;
        float: right;
        margin-left: 10px;
        position: relative;
        top: 6px;
        @include rotate(0deg);
        transition: .3s;
        @include respond-to(tablet-landscape) {
          top: 0;
          float: none;
          display: inline-block;
        }
      }
    }

    .dropdon-list {
      display: none;
      max-width: 400px;
      width: 100%;
      border: 0px solid red;
      top: 44px;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
      position: absolute;
      background-color: $color-white;
      z-index: 5555;
      padding-left: 30px;
      @include respond-to(mobile) {
        max-width: 100%;
        top: 30px;

      }
      li {
        list-style-type: none;
        font-family: $font-stack;
        font-size: 1rem;
        line-height: 1.5;
        text-align: left;
        color: #1a1a18;
        font-weight: 400;
        padding-top: 12px;
        padding-bottom: 12px;
        border-bottom: 1px solid rgba(156, 157, 157, 0.2);
        & > a {
          color: #1a1a18;
          text-decoration: none;
          -webkit-transition: color $default-hover-transition-speed $easing;
          -moz-transition: color $default-hover-transition-speed $easing;
          -ms-transition: color $default-hover-transition-speed $easing;
          -o-transition: color $default-hover-transition-speed $easing;
          transition: color $default-hover-transition-speed $easing;
          &:hover {
            outline: 0;
            font-weight: bold;
            color: $color-brown;
            -webkit-transition: color $default-hover-transition-speed $easing;
            -moz-transition: color $default-hover-transition-speed $easing;
            -ms-transition: color $default-hover-transition-speed $easing;
            -o-transition: color $default-hover-transition-speed $easing;
            transition: color $default-hover-transition-speed $easing;
            cursor: pointer; /* hand-shaped cursor */
          }
        }
        &:last-child {
          border-bottom: 0;
        }
      }
    }

    @include modifier('open') {
      .dropdown-title {
        &:after {
          @include rotate(-180deg);
          transition: .3s;
        }
      }
      .dropdon-list {
        display: block;
      }
    }
  }

  @include element('col-grid') {
    @include grid-container(row, nowrap, center, flex-start);

    @include respond-to(mobile) {
      overflow-x: scroll;
    }

    @include modifier('five-items') {
      .sub-header__col-grid-column {
        padding-left: 20px;
        padding-right: 20px;
        @include respond-to(desktop-big) {
          padding-left: 15px;
          padding-right: 15px;
        }
        @include respond-to(tablet-landscape) {
          padding-left: 6px;
          padding-right: 6px;
        }
        .cell {
          @include respond-to(desktop-big) {
            padding-left: 25px;
            padding-right: 25px;
          }
          @include respond-to(desktop) {
            padding-left: 15px;
            padding-right: 15px;
          }
          @include respond-to(tablet-landscape) {
            padding-left: 5px;
            padding-right: 5px;
          }
        }
      }
    }

  }

  @include element('col-grid-column') {
    @include grid-item();
    padding-right: 35px;
    padding-left: 35px;
    font-family: $font-stack;
    font-size: 1rem;
    line-height: 1;
    color: $color-brown;
    position: relative;

    &:before {
      content: '';
      display: block;
      border-right: solid 1px #ebebeb;
      height: 21px;
      position: absolute;
      top: 23px;
      right: 0;
    }
    &:last-child:before {
      border-right: 0;
    }

    @include respond-to(desktop-big) {
      padding-right: 22px;
      padding-left: 22px;
    }
    @include respond-to(desktop) {
      padding-right: 20px;
      padding-left: 20px;
    }

    @include respond-to(mobile) {
      padding-right: 10px;
      padding-left: 10px;
    }

    & + .sub-header__col-grid-column {

    }

    .cell {
      height: 66px;
      padding-left: 35px;
      padding-right: 35px;
      white-space: nowrap;
      @include respond-to(desktop) {
        padding-left: 20px;
        padding-right: 20px;
      }
      @include respond-to(tablet-landscape) {
        padding-left: 10px;
        padding-right: 10px;
      }
      @include respond-to(mobile) {
        padding-left: 5px;
        padding-right: 5px;
      }
      > a {
        position: relative;
        top: 23px;
        color: $color-brown;
        text-decoration: none;
        -webkit-transition: color $default-hover-transition-speed $easing;
        -moz-transition: color $default-hover-transition-speed $easing;
        -ms-transition: color $default-hover-transition-speed $easing;
        -o-transition: color $default-hover-transition-speed $easing;
        transition: color $default-hover-transition-speed $easing;
        &:hover {
          outline: 0;
          color: $color-black;
          -webkit-transition: color $default-hover-transition-speed $easing;
          -moz-transition: color $default-hover-transition-speed $easing;
          -ms-transition: color $default-hover-transition-speed $easing;
          -o-transition: color $default-hover-transition-speed $easing;
          transition: color $default-hover-transition-speed $easing;
          cursor: pointer; /* hand-shaped cursor */
        }
      }
      &.cell-active {
        border-bottom: 2px solid $color-brown;
        > a {
          font-weight: bold;
          color: #1a1a18;
        }
      }
    }

    @include modifier('active') {
      position: relative;
      color: $font-color;
      > a {
        font-weight: bold;
        color: $font-color;
      }
    }
  }

  @include element('line') {
    width: 100%;
    height: 1px;
    border-top: solid 1px rgba(156, 157, 157, 0.2);
  }

  @include element('icon') {
    a {
      color: $font-color;
      text-decoration: none;
      @include transition-opt($color-brown, $color-brown, $default-hover-transition-speed, 0 0 5px 0);
    }
    &:before {
      content: '';
      display: block;
      float: left;
      margin-right: 15px;
      position: relative;
    }
    @include modifier('back') {
      &:before {
        background: url('../images/back.svg') no-repeat;
        width: 24px;
        height: 19px;
        top: -1px;
      }
    }
    @include modifier('shop') {
      &:before {
        background: url('../images/shops-small.svg') no-repeat;
        width: 20px;
        height: 22px;
        top: -4px;
      }
    }
    @include modifier('calendar') {
      &:before {
        background: url('../images/calendar-small.svg') no-repeat;
        width: 23px;
        height: 23px;
        top: -4px;
      }
    }
    @include modifier('sale') {
      &:before {
        background: url('../images/sale-small.svg') no-repeat;
        width: 23px;
        height: 23px;
        top: -2px;
      }
    }
    @include modifier('stages') {
      &:before {
        background: url('../images/stages-small.svg') no-repeat;
        width: 22px;
        height: 22px;
        top: -2px;
      }
    }
  }

}

