// ------------------------------------------------------------
// This file contains all styles related to the `main_page` module.
// ------------------------------------------------------------

@include block('main-page') {

  @include element('slider') {
    margin-top: 30px;

    @media only screen and (max-height: 860px) {
      margin-top: 20px;
    }
    @include respond-to(tablet-landscape) {
      margin-top: 0;
    }
  }

  @include element('nav-categories') {
    margin-top: 30px;
  }

  @media only screen and (max-height: 960px) {
    @include element('nav-categories') {
      margin-top: 15px;
    }
  }

}
