// ------------------------------------------------------------
// This file contains all styles related to the `social links` component.
// ------------------------------------------------------------

@include block('social-links') {
  color: $color-gray;
  @include modifier('title') {
    font-family: $font-stack;
    font-size: 0.75rem;
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  @include respond-to(tablet) {
    text-align: center;
    @include modifier('title') {
      display: none;
    }
  }

  ul {
    margin-top: 14px;
    list-style: none;
    font-size: 0;
    @include respond-to(tablet) {
      margin-top: 0;
    }

    li {
      display: inline-block;
      & + li {
        margin-left: 15px;
      }
      .social-links__icon {
        width: 40px;
        height: 40px;
        &.social-links__icon--facebook-icon {
          -webkit-mask-image: url('../images/facebook_big.svg');
          mask-image: url('../images/facebook_big.svg');
        }
        &.social-links__icon--twitter-icon {
          -webkit-mask-image: url('../images/twitter_big.svg');
          mask-image: url('../images/twitter.svg');
          width: 41px;
        }
        &.social-links__icon--instagram-icon {
          -webkit-mask-image: url('../images/instagram_big.svg');
          mask-image: url('../images/instagram.svg');
          width: 41px;
        }
      }
    }
  }

  @include modifier('small-icons') {
    ul {
      li {
        .social-links__icon {
          width: 30px;
          height: 30px;
          &.social-links__icon--facebook-icon {
            -webkit-mask-image: url('../images/facebook.svg');
            mask-image: url('../images/facebook.svg');
          }
          &.social-links__icon--twitter-icon {
            -webkit-mask-image: url('../images/twitter.svg');
            mask-image: url('../images/twitter.svg');
            width: 31px;
          }
          &.social-links__icon--instagram-icon {
            -webkit-mask-image: url('../images/instagram.svg');
            mask-image: url('../images/instagram.svg');
            width: 31px;
          }
        }
        & + li {
          margin-left: 10px;
        }
      }
    }
  }

  @include element('icon') {
    display: block;
    background-color: $color-brown;
    transition: all 0.5s ease;
    &:hover {
      cursor: pointer;
      background-color: $color-black;
    }
  }
}