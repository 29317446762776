// ------------------------------------------------------------
// This file contains all styles related to the `form` component.
// ------------------------------------------------------------

@include block('form') {

  width: 100%;
  text-align: center;

  @include modifier('left') {
    padding-left: 120px;

    @include respond-to(tablet-landscape) {
      padding-left: 0;
    }
  }

  @include element('btn') {
    margin: 0 auto;
    text-align: center;
    padding: 0 80px;
    height: 40px;
    font-family: $font-stack;
    font-size: 1.125rem;
    font-weight: 500;
    color: $color-white;
    cursor: pointer;
    border: 0;
    &:focus {
      outline:0;
    }
    @include modifier('send') {
      background-color: $color-brown;
    }
    @include modifier('top') {
      margin-top: 40px;
    }

    @include modifier('animation-send') {
      top: 40px;
      border: 2px solid transparent;
      color: $color-white;
      @include border-radius(0);
      position: relative;
      overflow: hidden;
      transform: translateY(-50%);
      .text {
        &.active {
          transform: translateY(-350%) scale(0);
          transition: .35s cubic-bezier(.34,-0.61,1,.64);
        }
      }
      &.active {
        padding: 0px 80px;
        transition: .4s cubic-bezier(.35,-0.77,.67,1.88);
      }
      .loader {
        position: absolute;
        width: calc(0% - 4px);
        height: calc(100% - 4px);
        background-color: $color-white;
        left: 2px;
        top: 2px;
        @include border-radius(0);
        &.active {
          width: calc(100% - 4px);
          transition: 1.3s ease-in-out;
          transition-delay: .5s
        }
      }
      .done {
        margin-top: -20px;
        transform: translateY(300%) scale(0);
        &.active {
          transform: translateY(0%) scale(1);
          transition: .4s cubic-bezier(.34,-0.61,1,.64);
        }
      }
      &.finished {
        transition: .1s cubic-bezier(.35,-0.77,.67,1.88);
        .loader {
          background: $color-brown;
        }
      }
    }
  }

  @include element('form-successful') {
    display: none;
    .text {
      font-family: $font-stack;
      font-size: 1.25rem;
      font-weight: 600;
    }
    @include respond-to(tablet){
      padding-top: 40px;
      padding-bottom: 40px;
      margin-top: 0;
    }
    .send-another {
      display: block;
      margin-top: 50px;
      font-size: 1.125rem;
      color: $color-brown;
      font-weight: 600;
      span {
        @include transition-opt($color-brown, $color-brown, $default-hover-transition-speed, 0 0 7px 0);
      }
    }
  }

}

button {
  outline: 0;

  &.active {
    outline: 0;
  }
}
