// ------------------------------------------------------------
// This file contains very basic styles.
// ------------------------------------------------------------

html {
  -webkit-font-smoothing: antialiased;
  height: 100%;
}

// Set up a decent box model and font size on the root element
:root {
  box-sizing: border-box;
  font-size: $font-size;
}

// Make all elements from the DOM inherit from the root
// box-sizing value. This will give us the same result,
// and make it easier to change the box-sizing in plugins
// or other components that leverage other behavior.
*, *:after, *:before {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

// Border-box isn’t welcome on images
img {
  box-sizing: content-box;
}

// Prevent tap highlight on mobile devices
input, textarea, button, select, a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}