
@mixin top-left{
  @include line-opt(pseudoafter, $transition-height: $default-hover-height);
  @include line-opt(pseudobefore, $transition-height: $max-height, $transition-width: $default-hover-width);
  &:before {
      bottom: 0%;
      right: 0;
  }

  &:after {
      bottom: 0;
      right: 0; 
  }
}