@mixin double-linethrough-left-right {
   @include line-opt(pseudoafter, $height: $linethrough-height, $transition-height: $linethrough-height);
  @include line-opt(pseudobefore, $height: $linethrough-height, $transition-height: $linethrough-height);

  &:after {
    right: 0%; 
    top: 44%;
    z-index: 9; 
  } 

  &:before {
    left: 0%; 
    bottom: 10px;
    z-index: 9;
    top: 54%;  
  } 
}