/**
 * Owl Carousel v2.2.1
 * Copyright 2013-2017 David Deutsch
 * Licensed under  ()
 */

.owl-carousel {
  -webkit-tap-highlight-color: transparent;
  position: relative;
  .owl-item {
    -webkit-tap-highlight-color: transparent;
    position: relative;
  }
  display: none;
  width: 100%;
  z-index: 1;
  .owl-stage {
    position: relative;
    -ms-touch-action: pan-Y;
    -moz-backface-visibility: hidden;
    &:after {
      content: ".";
      display: block;
      clear: both;
      visibility: hidden;
      line-height: 0;
      height: 0;
    }
  }
  .owl-stage-outer {
    position: relative;
    overflow: hidden;
    -webkit-transform: translate3d(0, 0, 0);
  }
  .owl-item, .owl-wrapper {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
  }
  .owl-item {
    min-height: 1px;
    float: left;
    -webkit-backface-visibility: hidden;
    -webkit-touch-callout: none;
    img {
      display: block;
      width: 100%;
    }
  }
  .owl-dots.disabled, .owl-nav.disabled {
    display: none;
  }
}

.no-js .owl-carousel {
  display: block;
}

.owl-carousel {
  &.owl-loaded {
    display: block;
  }
  .owl-dot {
    cursor: pointer;
    cursor: hand;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .owl-nav {
    .owl-next, .owl-prev {
      cursor: pointer;
      cursor: hand;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
  }
  &.owl-loading {
    opacity: 0;
    display: block;
  }
  &.owl-hidden {
    opacity: 0;
  }
  &.owl-refresh .owl-item {
    visibility: hidden;
  }
  &.owl-drag .owl-item {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  &.owl-grab {
    cursor: move;
    cursor: grab;
  }
  &.owl-rtl {
    direction: rtl;
    .owl-item {
      float: right;
    }
  }
  .animated {
    animation-duration: 1s;
    animation-fill-mode: both;
  }
  .owl-animated-in {
    z-index: 0;
  }
  .owl-animated-out {
    z-index: 1;
  }
  .fadeOut {
    animation-name: fadeOut;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}


.owl-height {
  transition: height .5s ease-in-out;
}

.owl-carousel {
  .owl-item {
    .owl-lazy {
      opacity: 0;
      transition: opacity .4s ease;
    }
    img.owl-lazy {
      transform-style: preserve-3d;
    }
  }
  .owl-video-wrapper {
    position: relative;
    height: 100%;
    background: #000;
  }
  .owl-video-play-icon {
    position: absolute;
    height: 80px;
    width: 80px;
    left: 50%;
    top: 50%;
    margin-left: -40px;
    margin-top: -40px;
    background: url(owl.video.play.png) no-repeat;
    cursor: pointer;
    z-index: 1;
    -webkit-backface-visibility: hidden;
    transition: transform .1s ease;
    &:hover {
      -ms-transform: scale(1.3, 1.3);
      transform: scale(1.3, 1.3);
    }
  }
  .owl-video-playing {
    .owl-video-play-icon, .owl-video-tn {
      display: none;
    }
  }
  .owl-video-tn {
    opacity: 0;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    transition: opacity .4s ease;
  }
  .owl-video-frame {
    position: relative;
    z-index: 1;
    height: 100%;
    width: 100%;
  }
}