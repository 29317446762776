@mixin fill-closing-vertical{
	@include line-opt(pseudobefore, $width: $max-width, $transition-height: $half-height);
	@include line-opt(pseudoafter, $width: $max-width, $transition-height: $half-height);
	&:hover {
		color: $color !important;
	}

	&:before {
		top: 0%;
		right: 0;
	}

	&:after {
		bottom: 0%;
		left: 0;
	}
}