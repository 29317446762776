// ------------------------------------------------------------
// This file contains all styles related to the `navigation-icons` component.
// ------------------------------------------------------------

@include block('navigation-icons') {

  max-width: 1167px;
  width: 100%;
  margin: 0 auto;

  @include respond-to(large-desktop) {
    max-width: 1367px;
  }

  @include element('columns') {
    @include grid-container(row, nowrap, center, space-between);
    @include respond-to(tablet) {
      @include grid-container(column, nowrap, center);
    }
  }

  @include element('column') {
    @include grid-container(column, nowrap, center, center);
    min-width: 180px;
    min-height: 160px;
    background: $color-white;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
    cursor: pointer; /* hand-shaped cursor */
    text-decoration: none;
    z-index: 1000;
    @include respond-to(large-desktop) {
      min-width: 230px;
      min-height: 210px;
    }
    @include respond-to(tablet-landscape) {
      min-width: 140px;
      min-height: 130px;
    }
    @include respond-to(tablet) {
      min-width: 240px;
      max-height: 220px;
      & + .navigation-icons__column {
        margin-top: 40px;
      }
    }
    @include modifier('block') {
      img {
        display:block;
        margin: auto;
      }
      .icon {
        display: block;
        margin: 0 auto;
        transition: all 0.5s ease;
        &.icon--notikumi {
          width: 58px;
          height: 58px;
          background: url('../images/notikumi.svg') no-repeat;
        }
        &.icon--akcijas {
          width: 46px;
          height: 56px;
          background: url('../images/akcijas.svg') no-repeat;
        }
        &.icon--shops {
          width: 51px;
          height: 58px;
          background: url('../images/shops.svg') no-repeat;
        }
        &.icon--stages {
          width: 58px;
          height: 59px;
          background: url('../images/stages.svg') no-repeat;
        }
        &.icon--gift {
          width: 58px;
          height: 58px;
          background: url('../images/gift.svg') no-repeat;
        }
      }
      .title {
        margin-top: 26px;
        font-family: $font-stack;
        font-size: 0.875rem;
        font-weight: bold;
        text-align: center;
        color: $font-color;
        span {
          @include transition-opt($color-brown, $color-brown, $default-hover-transition-speed, 0 0 10px 0);
        }
        @media only screen and (max-height: 860px) {
          margin-top: 16px;
        }
      }
    }
    &:hover {
      .navigation-icons__column--block {
        .icon {
          &.icon--notikumi {
            background: url('../images/notikumi_filled.svg') no-repeat;
          }
          &.icon--akcijas {
            background: url('../images/akcijas_filled.svg') no-repeat;
          }
          &.icon--shops {
            background: url('../images/shops_filled.svg') no-repeat;
          }
          &.icon--stages {
            background: url('../images/stages_filled.svg') no-repeat;
          }
          &.icon--gift {
            background: url('../images/gift_filled.svg') no-repeat;
          }
        }
      }
      .title {
        position: relative;
        z-index: 2;
        text-decoration: none;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -webkit-touch-callout: none;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        white-space: nowrap;
        overflow: hidden;
        span {
          outline: 0;
          color: $color-brown;
          -webkit-transition: color $default-hover-transition-speed $easing;
          -moz-transition: color $default-hover-transition-speed $easing;
          -ms-transition: color $default-hover-transition-speed $easing;
          -o-transition: color $default-hover-transition-speed $easing;
          transition: color $default-hover-transition-speed $easing;
          cursor: pointer;
        }
      }
    }
  }
  @include respond-to(tablet-landscape) {
    padding: 0 25px;
  }

  @media only screen and (max-height: 960px) {
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    @include element('column') {
      min-width: 150px;
      min-height: 120px;
    }
  }

}
