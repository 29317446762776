// ------------------------------------------------------------
// This file contains all styles related to the `main` component.
// ------------------------------------------------------------

@include block('main') {
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  z-index: 1;
  @include element('container') {
    max-width: $main-container-width;
    width: 100%;
    margin: 0 auto;
    border: 0;
    @include modifier('with-padding') {
      padding: 0 $base_padding;
      @include respond-to('tablet') {
        padding: 0 $base_padding_tablet;
      }
      @include respond-to('mobile') {
        padding: 0 $base_padding_mobile;
      }
    }
    @include respond-to(large-desktop) {
      max-width: $main-container-ultra-width;
      padding: 0 $base-padding-ultra;
    }
  }
}
