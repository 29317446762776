@mixin fill-opening-horizontal{
  @include line-opt(pseudobefore, $height: $max-height);
   &:hover {
    color: $color !important;
  }
  
  &:before {
  top: 0%;
  left: 0;
  right: 0;
  margin: 0 auto;
  }
}