// ------------------------------------------------------------
// This file contains all styles related to the `flor-map` component.
// ------------------------------------------------------------

@include block('floor-map') {

  padding-top: 85px;
  max-width: 1105px;
  width: 100%;
  margin: 0 auto;

  @include respond-to(tablet) {
    padding-top: 60px;
  }

  @include respond-to(mobile) {
    padding-top: 40px;
  }

  @include element('container') {
    max-width: $main-container-width;
    width: 100%;
    margin: 0 auto;
    border: 0;
    @include modifier('with-padding') {
      padding: 0 $base_padding;
      @include respond-to('tablet') {
        padding: 0 $base_padding_tablet;
      }
      @include respond-to('mobile') {
        padding: 0 $base_padding_mobile;
      }
    }
    @include respond-to(large-desktop) {
      max-width: $main-container-ultra-width;
      padding: 0 $base-padding-ultra;
    }
  }


  @include element('floor') {
    img {
      width: 100%;
      display: block;
    }
  }

  @include element('info-grid') {
    @include grid-container();
    margin-bottom: 30px;
    @include respond-to(mobile) {
      margin-top: 30px;
      margin-bottom: 50px;
    }
    @include modifier('center') {
      .floor-map__info-column {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  @include element('info-column') {
    position: relative;
    margin-top: 80px;
    margin-left: 0;
    margin-right: inherit;
    &:after {
      content: '';
      display: block;
      border-right: 1px solid #ebebeb;
      right: 40px;
      top: 0;
      height: 100%;
      position: absolute;
      @include respond-to(mobile) {
        content: none;
      }
    }

    &:nth-child(3n) {
      &:after {
        content: none;
      }
    }

    @include respond-to(tablet) {
      margin-top: 40px;

      &:nth-child(3n) {
        &:after {
          content: '';
        }
      }
      &:nth-child(2n) {
        &:after {
          content: none;
        }
      }
    }

    &:last-child {
      &:after {
        content: none;
      }
    }

    @include grid-item(4);

    @include respond-to(tablet) {
      @include grid-item(6);
    }

    @include respond-to(mobile) {
      margin-top: 40px;
      @include grid-item(12);

      &:nth-child(3n) {
        &:after {
          content: none;
        }
      }
    }

    padding-right: 40px;
    .title {
      font-family: $font-stack;
      font-size: 1.5rem;
      line-height: 1.33;
      text-align: left;
      font-weight: 500;
    }
  }



}
