// ------------------------------------------------------------
// This file contains all styles related to the `pagination` component.
// ------------------------------------------------------------

@include block('pagination') {
  display: block;
  list-style-type: none;
  li {
    display: inline-block;
  }

  @include modifier('align-center') {
    text-align: center;
  }

  @include element('arrow') {
    position: relative;

    & > a {
      border: solid $color-brown;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 4px;
    }
    @include modifier('left') {
      top: 3px;
      margin-right: 30px;
      @include rotate(135deg);
      @include respond-to(mobile) {
        margin-right: 15px;
      }
    }
    @include modifier('right') {
      top: 0;
      margin-left: 30px;
      @include rotate(-45deg);
      @include respond-to(mobile) {
        margin-left: 15px;
      }
    }
  }

  @include element('page') {
    position: relative;
    width: 32px;
    height: 32px;
    line-height: 32px;
    font-family: $font-stack;
    font-size: 1.125rem;
    font-weight: bold;
    color: #4b4b4b;
    text-align: center;
    & > a {
      color: #4b4b4b;
      text-decoration: none;
    }
    @include modifier('active') {
      color: $color-brown;
      @include border-radius(50%);
      @include box-shadow(0 2px 4px 0 rgba(0, 0, 0, 0.2));
      & > a {
        text-decoration: none;
        cursor: pointer;
        color: $color-brown;
      }
    }
    &+.pagination__page {
      margin-left: 15px;
      @include respond-to(mobile) {
        margin-left: 10px;
      }
    }
  }

  @include respond-to('mobile') {
    padding: 0 10px;
  }

}
