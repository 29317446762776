// ------------------------------------------------------------
// This file contains all styles related to the `maps` component.
// ------------------------------------------------------------

@include block('map') {
  max-width: 100%;
  width: 100%;

  @include modifier('right') {
    padding-right: 120px;
    @include respond-to('tablet-landscape') {
      padding-right: 0;
    }
  }

  @include element('google-map') {
    height: 318px;
    background-color: $color-white;
  }
  @include element('details') {
    @include grid-container();
    margin-top: 40px;

    @include respond-to('mobile') {
      margin-top: 20px;
      margin-left: 0;
    }
  }
  @include element('details_column') {
    $side-padding : 40px; // padding bettwen blocks
    @include respond-to(mobile) {
      $side-padding : 0;
    }
    @include grid-item(4);
    position: relative;
    padding-right: $side-padding;
    @include respond-to(tablet-landscape) {
      @include grid-item(12);
    }
    @include respond-to(mobile) {
      @include grid-item(12);
    }
    &:last-child {
      padding-right: 0;
    }
    &:before {
      content: '';
      height: 21px;
      border-left: 1px solid $color-gray-light;
      float: left;
      padding-right: 30px;
      @include respond-to(tablet-landscape) {
        border-left: 0;
        padding-right: 0;
      }
      @include respond-to(mobile) {
        border-left: 0;
        padding-right: 0;
      }
    }
    &:first-child {
      &:before {
        border-left: 0;
        padding-right: 0;
      }
    }
    @include modifier('address') {
      font-family: $font-stack;
      font-size: 1rem;
      font-weight: bold;
      line-height: 1.31;
      text-align: left;
      color: $font-color;
      margin-left: 20px;
      max-width: 140px;
      width: 100%;
      &:before {
        content: '';
        position: absolute;
        background: url('../images/location.svg') no-repeat;
        width: 14px;
        height: 14px;
        display: block;
        float: left;
        margin-right: 9px;
        top: 4px;
        left: 0;
      }
    }
    @include modifier('phone') {
      font-family: $font-stack;
      font-size: 1rem;
      font-weight: bold;
      line-height: 1.31;
      text-align: left;
      color: $font-color;
      white-space: nowrap;
      a[href^="tel"]{
        color:inherit;
        text-decoration:none;
      }
      &:before {
        content: '';
        -webkit-mask-image: url('../images/phone.svg');
        mask-image: url('../images/phone.svg');
        background-color: $color-brown;
        width: 16px;
        height: 16px;
        display: block;
        float: left;
        margin-right: 9px;
        position: relative;
        top: 4px;
      }
    }
    @include modifier('email') {
      font-family: $font-stack;
      font-size: 1rem;
      font-weight: bold;
      line-height: 1.31;
      text-align: left;
      color: $color-brown;
      white-space: nowrap;
      &:before {
        content: '';
        -webkit-mask-image: url('../images/mail.svg');
        mask-image: url('../images/mail.svg');
        background-color: $color-brown;
        width: 18px;
        height: 12px;
        display: block;
        float: left;
        margin-right: 9px;
        position: relative;
        top: 5px;
      }
      & > a {
        color: $color-brown;
        text-decoration: none;
        @include transition-opt($color-brown, $color-brown, $default-hover-transition-speed, 0 0 5px 0);
      }
    }
    @include respond-to(tablet-landscape) {
      & +.map__details_column {
        margin-top: 15px;
      }
    }
  }

  @include element('marker') {
    box-sizing: border-box;
    font-family: $font-stack;
    font-size: 0.8125rem;
    padding: 5px;
    @include modifier('link') {
      font-weight: 600;
      margin-top: 5px;
      > a {
        color: $color-brown;
        @include transition-opt($color-brown, $color-brown, $default-hover-transition-speed, 0 0 3px 0);
      }
    }
  }

}
