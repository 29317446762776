@include block('input') {
  position: relative;
  z-index: 1;
  display: inline-block;
  margin: 0;
  width: 100%;
  vertical-align: top;
  font-family: $font-stack;
  font-size: 18px;
  line-height: 1.56;
  text-align: left;
  color: #1a1a18;
  padding-top: 2px;
  padding-bottom: 4px;

  @include element('field') {
    position: relative;
    display: block;
    padding: 0.8em;
    width: 60%;
    border: none;
    border-radius: 0;
    background: #f0f0f0;
    -webkit-appearance: none;
    /* for box shadows to show on iOS */
    &:focus {
      outline: none;
    }
  }

  @include element('label') {
    display: inline-block;
    float: right;
    padding: 0 1em;
    width: 40%;
    font-family: $font-stack;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 2px;
    text-align: left;
    color: #9b9b9b;
    text-transform: uppercase;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  @include element('label-content') {
    position: relative;
    display: block;
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 10px;
    width: 100%;
  }

  @include element('icon') {
    position: absolute;
    top: 40px;
    right: 8px;
    width: 16px;
    height: 16px;
    display: inline-block;
    cursor: pointer;
    @include modifier('error') {
      background: url('../images/field_error.svg') no-repeat;
    }
  }
}

.graphic {
  position: absolute;
  top: 0;
  left: 0;
  fill: none;
}

/* Individual styles */

/* Hoshi */

.input--hoshi {
  overflow: hidden;
}

.input__field--hoshi {
  margin-top: 1em;
  width: 100%;
  background: transparent;
  color: #1a1a18;
  font-weight: 400;
}

.input__label--hoshi {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0 0.25em;
  width: 100%;
  height: calc(100% - 1em);
  text-align: left;
  pointer-events: none;
}
.input--filled .input__label--hoshi {
  font-size: 10px;
  letter-spacing: 1.7px;
}

.input__label-content--hoshi {
  position: absolute;
}

.input__label--hoshi {
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 10px);
    border-bottom: 2px solid transparentize($color-brown, .8);
  }
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 10px);
    border-bottom: 1px solid #B9C1CA;
    margin-top: 0;
    border-bottom: 2px solid red;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    -webkit-transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
  }
}

.input__label--hover-color:after {
  border-color: transparentize($color-brown, .4);
}

.input__field--hoshi:focus + .input__label--hoshi::after, .input--filled .input__label--hoshi::after {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.input__field--hoshi:focus + .input__label--hoshi .input__label-content--hoshi, .input--filled .input__label-content--hoshi {
  -webkit-animation: anim-1 0.3s forwards;
  animation: anim-1 0.3s forwards;
}

@-webkit-keyframes anim-1 {
  50% {
    opacity: 0;
    -webkit-transform: translate3d(1em, 0, 0);
    transform: translate3d(1em, 0, 0);
  }

  51% {
    opacity: 0;
    -webkit-transform: translate3d(-1em, -40%, 0);
    transform: translate3d(-1em, -40%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, -40%, 0);
    transform: translate3d(0, -40%, 0);
  }
}

@keyframes anim-1 {
  50% {
    opacity: 0;
    -webkit-transform: translate3d(1em, 0, 0);
    transform: translate3d(1em, 0, 0);
  }

  51% {
    opacity: 0;
    -webkit-transform: translate3d(-1em, -40%, 0);
    transform: translate3d(-1em, -40%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, -40%, 0);
    transform: translate3d(0, -40%, 0);
  }
}