@mixin overline-left-underline-right {
    @include line-opt(pseudoafter, $transition-height: $default-hover-height);
    @include line-opt(pseudobefore, $transition-height: $default-hover-height);

    &:after {
        top: 0%;
        right: 0%;
    }

    &:before {
        bottom: 0%;
        left: 0%; 
    }
}