// ------------------------------------------------------------
// This file contains all project-related variables.
// ------------------------------------------------------------

// Paths to assets
// Used by `image()` and `font()` getter functions.
$assets-path: '../';
$fonts-path:  '#{$assets-path}/fonts';
$images-path: '#{$assets-path}/images';

// Breakpoints
// Used by `respond-to()` responsive breakpoint manager.
$breakpoints: (
  'mobile':  (max-width: 480px),
  'tablet':  (max-width: 768px),
  'tablet-landscape': (max-width: 960px),
  'desktop': (max-width: 1024px),
  'desktop-big': (max-width: 1500px),
  'large-desktop': (min-width: 2000px)
);
// Base font settings
$font-size: 16px;
$font-line-height: 1.385rem;
$font-stack: (
  'Exo 2',
  sans-serif
);

// Colors
$background-main-color: #fcfcfc;
$font-color: #000;

$color-black: #000000;
$color-white: #ffffff;
$color-brown: #986540;
$color-red: #e54d42;
$color-gray: #9b9b9b;
$color-gray-light: #ebebeb;

// Sizes
$main-container-width: 1440px;
$main-container-ultra-width: 1640px;
$header-main-height: 77px;


$base_padding: 60px;
$base-padding-ultra: 0px;
$base_padding_tablet: 40px;
$base_padding_mobile: 20px;